/* eslint-disable prettier/prettier */
import templateOnlyComponent from '@ember/component/template-only';

interface ContainersMapContainerSignature {
  Element: HTMLDivElement;
  Args: {
    isShown: boolean;
    isIdle: boolean;
    isLoading: boolean;
    isExpanded: boolean;
    onLoadFailed: () => {};
  };
  Blocks: {
    visible: [];
  };
}

const ContainersMapContainerComponent = templateOnlyComponent<ContainersMapContainerSignature>();

export default ContainersMapContainerComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TntMap::Container': typeof ContainersMapContainerComponent;
  }
}
