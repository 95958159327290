/* eslint-disable prettier/prettier */
import leaflet, { type LatLngExpression } from 'leaflet';

// https://en.wikipedia.org/wiki/Heading_(navigation)
// Ship course might not exactly be correct
// This specifies north pole direction within error of 6 degrees

const headingVariations: { name: string; range: [number, number] }[] = [
  { name: 'North', range: [354, 6] },
  { name: 'North-East', range: [7, 83] },
  { name: 'East', range: [84, 108] },
  { name: 'South-East', range: [109, 173] },
  { name: 'South', range: [174, 198] },
  { name: 'South-West', range: [199, 263] },
  { name: 'West', range: [264, 288] },
  { name: 'North-West', range: [289, 353] },
];

const isInRange = (value: number, start: number, end: number) => {
  if (start <= end) {
    return value >= start && value <= end;
  } else {
    return value >= start || value <= end;
  }
};

export const humanizeDegrees = (degrees: number): string => {
  for (const variation of headingVariations) {
    const [start, end] = variation.range;

    if (isInRange(degrees, start, end)) {
      return variation.name;
    }
  }

  return 'North';
};

export const isAntimeridianCrossing = (point1: LatLngExpression, point2: LatLngExpression) => {
  const antimeridian = 180; // Antimeridian line longitude

  // Ensure the longitudes are in the range [-180, 180]
  const lng1 = leaflet.latLng(point1).wrap().lng;
  const lng2 = leaflet.latLng(point2).wrap().lng;

  // Check if the longitudes have opposite signs and cross the antimeridian
  if ((lng1 < 0 && lng2 >= 0) || (lng1 >= 0 && lng2 < 0)) {
    // Check if the absolute difference between the longitudes is greater than 180
    if (Math.abs(lng1 - lng2) > antimeridian) {
      return true; // Crossing the antimeridian
    }
  }

  return false; // Not crossing the antimeridian
};
