import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @class HarborFeedComponent
 * @type {{id: number, name: string}} author
 * @type {{id: number, body: string, createdAt: date, author: author}} note
 * @param {string} comment
 * @param {boolean} hideForm
 * @param {note[]} [notes]
 * @function onSubmit
 */
export default class HarborFeedComponent extends Component {
  @action
  submitForm(event) {
    event.preventDefault();

    this.args.onSubmit(event.target.elements.comment.value);
  }

  @action
  cancelEdit() {
    this.args.onCancelEdit(null);
  }
}
