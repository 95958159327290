import templateOnlyComponent from '@ember/component/template-only';
import type { Icons } from './icon.ts';

interface MultiMapTooltipSignature {
  Element: HTMLDivElement;
  Args: {
    icon: Icons;
    label?: string;
  };
  Blocks: {
    title: [];
    body: [];
  };
}

const MultiMapTooltipComponent = templateOnlyComponent<MultiMapTooltipSignature>();

export default MultiMapTooltipComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MultiMap::Tooltip': typeof MultiMapTooltipComponent;
  }
}
