/* eslint-disable prettier/prettier */
import Component from '@glimmer/component';
import { createPopper, type Placement } from '@popperjs/core';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import type { HelperLike, ModifierLike } from '@glint/template';
import type { ClickOutsideSignature } from 'ember-click-outside/modifiers/on-click-outside';

export interface HarborButtonItemSignature {
  label: string;
  icon: string;
  prefix?: string;
  action: () => void;
  testSelector?: string;
}

export interface HarborButtonSignature {
  Element: HTMLButtonElement;
  Args: {
    variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'plain';
    size?: 'small' | 'medium' | 'large';
    type?: 'button' | 'submit' | 'reset';
    prefix?: string;
    icon?: string;
    outline?: boolean;
    showChevron?: boolean;
    disabled?: boolean;
    items?: HarborButtonItemSignature[];
    itemsPlacement?: Placement,
    onClick?: (e: MouseEvent) => void;
  };
  Blocks: {
    default: [];
    items: [HarborButton];
  };
}

const mapping = {
  far: 'tw-bg-gray-500 tw-text-white',
} as const;

export default class HarborButton extends Component<HarborButtonSignature> {
  @tracked popper?: ReturnType<typeof createPopper>;
  @tracked buttonElement?: HTMLButtonElement;
  @tracked showMenu = false;

  get size() {
    return this.args.size || 'medium';
  }

  get outline() {
    return this.args.outline || false;
  }

  get type() {
    return this.args.type || 'button';
  }

  get iconClass() {
    if (!this.args.prefix) {
      return '';
    }

    return mapping[this.args.prefix as keyof typeof mapping] || '';
  }

  @action
  onClick(e: MouseEvent) {
    if (isPresent(this.args.items) || this.args.showChevron) {
      this.showMenu = true;
      return;
    } else {
      this.args.onClick?.(e);
    }
  }

  @action
  onHideMenu() {
    this.showMenu = false;
  }

  @action
  registerButton(element: HTMLButtonElement) {
    this.buttonElement = element;
  }

  @action
  registerMenu(element: HTMLElement) {
    this.popper?.destroy();
    this.popper = createPopper(this.buttonElement as HTMLButtonElement, element, {
      placement: this.args.itemsPlacement || 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: { offset: [0, 4] },
        },
      ],
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Harbor::Button': typeof HarborButton;
    'on-click-outside': ModifierLike<ClickOutsideSignature>;
    'queue': HelperLike<{
      Args: { Positional: [() => void, () => void] | [] },
      Return: (e: Event) => unknown;
    }>;
  }
}
