/* eslint-disable prettier/prettier */
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import moment, { type Moment } from 'moment-timezone';

interface MultiMapTooltipArrivalTimeSignature {
  Element: HTMLTimeElement;
  Args: {
    timeZone: string;
    actualTime: string;
    originalEstimate?: string;
    currentEstimate?: string;
  };
}

export default class MultiMapTooltipArrivalTimeComponent extends Component<MultiMapTooltipArrivalTimeSignature> {
  timeLabel = 'ETA';
  dateTimeFormat = 'LL z';

  get isDelayed() {
    return this.daysDelayed && this.daysDelayed > 0;
  }

  get isEarly() {
    return this.daysDelayed && this.daysDelayed < 0;
  }

  get noTime() {
    return isEmpty(this.dateTime);
  }

  get dateTimeString() {
    return this.dateTime?.toString();
  }

  get dateTime() {
    return this.actualTimeInTZ || this.currentEstimateInTZ || this.originalEstimateInTZ;
  }

  get title() {
    if (this.noTime) {
      return `Vessel ${this.presentEvent} info is not available`;
    } else if (this.args.actualTime) {
      return `Vessel ${this.pastEvent} on ${moment(this.actualTimeInTZ).format('LL [at] HH:mm')}`;
    } else {
      let titleText = `Vessel ${this.futureEvent} on ${moment(this.currentEstimateInTZ).format('LL')}`;
      if (this.daysDelayed) {
        titleText += `, original ${this.timeLabel} was ${moment(this.originalEstimateInTZ).format('LL')}`;
      }
      return titleText;
    }
  }

  get noActual() {
    return isEmpty(this.args.actualTime);
  }

  get actualTimeInTZ() {
    return this.dateInTz(this.args.actualTime, this.args.timeZone);
  }

  get originalEstimateInTZ() {
    if (this.args.originalEstimate) {
      return this.dateInTz(this.args.originalEstimate, this.args.timeZone);
    }
  }

  get currentEstimateInTZ() {
    if (this.args.currentEstimate) {
      return this.dateInTz(this.args.currentEstimate, this.args.timeZone);
    }
  }

  get timeZoneAbbreviation() {
    if (this.dateTime) {
      return this.timeZoneAbbr(this.dateTime, this.args.timeZone);
    }
  }

  get daysDelayed() {
    if (this.args.actualTime) {
      return undefined;
    }
    if (!(this.args.originalEstimate && this.args.currentEstimate)) {
      return undefined;
    }
    const originalEta = moment(this.args.originalEstimate),
      currentEstimate = moment(this.args.currentEstimate);
    return currentEstimate.diff(originalEta, 'days');
  }

  get daysDelayedAbs() {
    if (this.daysDelayed) {
      return Math.abs(this.daysDelayed);
    }
  }

  get futureEvent() {
    return this.timeLabel == 'ETD' ? 'departing' : 'arriving';
  }

  get presentEvent() {
    return this.timeLabel == 'ETD' ? 'departure' : 'arrival';
  }

  get pastEvent() {
    return this.timeLabel == 'ETD' ? 'departed' : 'arrived';
  }

  dateInTz = (tzDate: Moment | string, timeZone: string) => {
    if (tzDate == undefined) {
      return null;
    }
    // When we don't know what the TZ is we parse in UTC so that's
    // what we should fall back to.
    const eventTimeZone = timeZone || 'Etc/UTC';
    return moment.tz(tzDate, eventTimeZone);
  };

  timeZoneAbbr = (tzDate: Moment | string, timeZone: string) => {
    if (timeZone == undefined) {
      return null;
    }
    const momentTz = moment.tz.zone(timeZone);
    if (momentTz == null) {
      return null;
    }
    return moment.tz.zone(timeZone)?.abbr(tzDate as unknown as number);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MultiMap::Tooltip::ArrivalTime': typeof MultiMapTooltipArrivalTimeComponent;
  }
}
