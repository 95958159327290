import templateOnlyComponent from '@ember/component/template-only';

interface HarborFeedCommentSignature {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Args: Record<string, any>;
}

const HarborFeedComment = templateOnlyComponent<HarborFeedCommentSignature>();

export default HarborFeedComment;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HarborFeedComment: typeof HarborFeedComment;
  }
}
