/* eslint-disable prettier/prettier */
import { JourneyPoint } from '../journey-point.ts';
import type * as GeoJSON from 'geojson';
import { isAntimeridianCrossing } from '../../native/utils.ts';
import { World } from '../world.ts';

interface VesselStyles {
  color: string;
  weight: number;
  dashArray?: number[] | string;
}

interface StyledGeoJSON {
  geojson: GeoJSON.Feature<GeoJSON.LineString>;
  style: VesselStyles;
}

const VESSEL_STYLES = {
  completed: {
    weight: 4,
    color: 'var(--green-600)',
  },
  inProgress: {
    weight: 4,
    dashArray: '10',
    color: 'var(--athens-gray-500)',
  },
};

type Coordinates = [number, number];

const applyOffset = (
  coordinates: Coordinates,
  isCrossingAntiMeridian: boolean,
  current: World,
  next: World | null,
): Coordinates => {
  let point = current.applyGeojsonOffset(coordinates);

  if (next && isCrossingAntiMeridian) {
    point = next.applyGeojsonOffset(coordinates);
  }

  return point;
};

export const completedVesselJourney = (journeyPoint: JourneyPoint): StyledGeoJSON => {
  const coordinates: GeoJSON.LineString['coordinates'] = [];

  const worlds = journeyPoint.worlds;
  const crossedIndex = journeyPoint.journeyPointCrossing?.positionsData.crossedIndex;
  const isCrossingAntiMeridian = Boolean(journeyPoint.journeyPointCrossing?.positionsData.isCrossingAntiMeridian);
  journeyPoint.vesselPositionsBetweenThisAndNextPoint.forEach((position, index) => {
    if (!position.next) {
      return;
    }

    if (crossedIndex && index >= crossedIndex) {
      coordinates.push(applyOffset(position.geojsonCoordinates, isCrossingAntiMeridian, worlds.current, worlds.next));
      coordinates.push(
        applyOffset(position.next.geojsonCoordinates, isCrossingAntiMeridian, worlds.current, worlds.next),
      );
    } else {
      // Stops drawing a line between points that crossed the 180th meridian.
      // Otherwise it results in drawing a line across the whole map
      if (!isAntimeridianCrossing(position.coordinates, position.next.coordinates)) {
        coordinates.push(applyOffset(position.geojsonCoordinates, false, worlds.current, worlds.next));
        coordinates.push(applyOffset(position.next.geojsonCoordinates, false, worlds.current, worlds.next));
      }
    }
  });

  return {
    style: VESSEL_STYLES.completed,
    geojson: {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates,
      },
      properties: null,
    },
  };
};

export const projectedFutureJourneyPath = (journeyPoint: JourneyPoint): StyledGeoJSON => {
  const coordinates: GeoJSON.LineString['coordinates'] = [];

  if (!journeyPoint.isJourneyLegFinished) {
    const worlds = journeyPoint.worlds;
    const pastPositionsCrossing = Boolean(journeyPoint.journeyPointCrossing?.positionsData.isCrossingAntiMeridian);
    const crossedIndex = journeyPoint.journeyPointCrossing?.futurePositionsData.crossedIndex;
    const isCrossingAntiMeridian =
      Boolean(journeyPoint.journeyPointCrossing?.futurePositionsData.isCrossingAntiMeridian) || pastPositionsCrossing;
    journeyPoint.futureVesselPositionsBetweenThisAndNextPoint.forEach((position, index) => {
      if (!position.next) {
        return;
      }

      if ((crossedIndex && index >= crossedIndex) || pastPositionsCrossing) {
        coordinates.push(applyOffset(position.geojsonCoordinates, isCrossingAntiMeridian, worlds.current, worlds.next));
        coordinates.push(
          applyOffset(position.next.geojsonCoordinates, isCrossingAntiMeridian, worlds.current, worlds.next),
        );
      } else {
        // Stops drawing a line between points that crossed the 180th meridian.
        // Otherwise it results in drawing a line across the whole map
        if (
          position.coordinates[0] &&
          position.next.coordinates[0] &&
          !isAntimeridianCrossing(position.coordinates, position.next.coordinates)
        ) {
          coordinates.push(applyOffset(position.geojsonCoordinates, false, worlds.current, worlds.next));
          coordinates.push(applyOffset(position.next.geojsonCoordinates, false, worlds.current, worlds.next));
        }
      }
    });
  }

  return {
    style: VESSEL_STYLES.inProgress,
    geojson: {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates,
      },
      properties: null,
    },
  };
};
