/* eslint-disable ember/no-get, prettier/prettier */
import { helper } from '@ember/component/helper';
import { get } from '@ember/object';

// import type PortModel from 'tnt-ui/models/port';
// We can't import the model's type directly because it's in the parent app
type FauxPortModel = {
  name: string;
  countryCode: string;
  stateAbbr: string;
};

export function portName(params: string[] | FauxPortModel[]) {
  const port = params[0];
  if (port == undefined) {
    return null;
  } else if ([port, get(port, 'code')].includes('@exists')) {
    return 'Present';
  } else if ([port, get(port, 'code')].includes('@not_exists')) {
    return 'Not present';
  } else if (get(port, 'countryCode') == 'US') {
    return get(port, 'name') + ', ' + get(port, 'stateAbbr');
  } else {
    return [get(port, 'name'), get(port, 'countryCode')].filter(x => x).join(', ');
  }
}

export default helper(portName);
