import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isTesting } from '@embroider/macros';
import { task, timeout } from 'ember-concurrency';
import leaflet, { type Map } from 'leaflet';
import { completedVesselJourney } from '../native/geojson/vessel-journey.ts';
import type { JourneyPoint } from '../native/journey-point.ts';
import type { Journey } from '../native/journey.ts';
import { type MapConfig } from '../native/map-data-loader.ts';
import { type Port, type Shipment, type Vessel } from '../native/map-data.ts';
import type { Universe } from '../native/universe.ts';

interface MultiMapSignature {
  Args: {
    isExpanded: boolean;
    toggleExpanded: () => {};
    config: MapConfig;
    onSelectVessel: (vessel: Vessel) => void;
    selectedVesselId?: string;
    didInitMap?: (map: Map) => void;
    multiverse?: Array<{
      portOfLading: Port | undefined; // TODO: It doesn't seem like we're passing this to the original map
      portOfDischarge: Port | undefined; // TODO: It doesn't seem like we're passing this to the original map
      shipment: Shipment;
      journey: Journey;
      universe: Universe;
    }>;
  };
}

export default class MultiMapComponent extends Component<MultiMapSignature> {
  @tracked leafletMapInstance: Map | null = null;

  mapCenterOn = {
    center: this.center,
    zoom: 2.2,
  };

  tilesetsURL = isTesting()
    ? '/assets/dev-tilesets/{z}-{x}-{y}.png'
    : `https://api.maptiler.com/maps/a891f240-5a0b-43a8-a6ac-8c69ab553f36/{z}/{x}/{y}.png?key=${this.args.config.maptilerKey}`;

  get center() {
    return leaflet.latLng(0, 0);
  }

  get hasSelectedVessel() {
    return Boolean(this.args.selectedVesselId);
  }

  toggleExpanded = () => {
    this.args.toggleExpanded();
  };

  initMap = (element: HTMLElement) => {
    this.leafletMapInstance = leaflet.map(element, {
      attributionControl: false,
      zoomSnap: 0.1,
      scrollWheelZoom: false,
    });

    // Shadow applied to individual markers would result in the shadow being rotated, which is not
    // the desired effect. It seems like we can't put `DivIcon`s onto custom panes, so we're
    // targeting the pane the vessels are in and applying a shadow to that.
    this.leafletMapInstance.getPane('markerPane')?.classList.add('tntm__vessels-pane');

    leaflet
      .tileLayer(this.tilesetsURL, {
        attribution: '',
      })
      .addTo(this.leafletMapInstance);

    // on load must be before setView
    // this.leafletMapInstance.on('load', this.onLoad); // This is only used for tracking map events
    this.leafletMapInstance.setView(this.mapCenterOn.center, this.mapCenterOn.zoom);

    this.args.didInitMap?.(this.leafletMapInstance);
  };

  // It's a task because size invalidation can happen multiple times, this gives enough time for events to join in
  resizeMapTask = task({ restartable: true }, async () => {
    await timeout(isTesting() ? 1 : 100);

    this.leafletMapInstance?.invalidateSize({ animate: true });
  });

  drawVesselPositionsTask = task({ maxConcurrency: 1 }, async (journeyPoint: JourneyPoint) => {
    // Draw vessel journey with positions
    if (journeyPoint.vesselJourney?.hasPositions) {
      const completedTracks = completedVesselJourney(journeyPoint);

      this.leafletMapInstance &&
        leaflet
          .geoJson(completedTracks.geojson, { style: completedTracks.style, interactive: false })
          .addTo(this.leafletMapInstance);
    }
  });
}
