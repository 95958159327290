import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isTesting } from '@embroider/macros';
import { task, timeout } from 'ember-concurrency';
import leaflet, { type LeafletEvent, type Map } from 'leaflet';
import { completedVesselJourney, projectedFutureJourneyPath } from '../native/geojson/vessel-journey.ts';
import { JourneyPoint } from '../native/journey-point.ts';
import { type Journey } from '../native/journey.ts';
import { type MapConfig } from '../native/map-data-loader.ts';
import { type Port, type Shipment } from '../native/map-data.ts';
import { Events } from '../native/tracking.ts';
import { type Universe } from '../native/universe.ts';

interface ContainersMapSignature {
  Args: {
    isExpanded: boolean;
    toggleExpanded: () => {};
    portOfLading: Port | undefined;
    portOfDischarge: Port | undefined;
    shipment: Shipment;
    journey: Journey;
    universe: Universe;
    config: MapConfig;
  };
}

export default class ContainersMapComponent extends Component<ContainersMapSignature> {
  @tracked leafletMapInstance: Map | null = null;

  lastZoomLevel = 1;

  mapCenterOn = {
    center: this.center,
    zoom: 1,
  };

  tilesetsURL = isTesting()
    ? '/assets/dev-tilesets/{z}-{x}-{y}.png'
    : `https://api.maptiler.com/maps/a891f240-5a0b-43a8-a6ac-8c69ab553f36/{z}/{x}/{y}.png?key=${this.args.config.maptilerKey}`;

  constructor(owner: unknown, args: ContainersMapSignature['Args']) {
    super(owner, args);
    this.args.universe.distributeJourneyPointsBetweenWorlds();
  }

  get center() {
    if (this.args.portOfLading?.latitude && this.args.portOfLading.longitude) {
      return leaflet.latLng(this.args.portOfLading.latitude, this.args.portOfLading.longitude);
    }

    return leaflet.latLng(0, 0);
  }

  @action
  onFeedbackForm() {
    this.args.journey.mapDataLoader.services.modals?.open('feedback-form', {
      boardId: '63adc4c209be0e451ecbd684',
      categoryId: '64e53f505540cb09a6739327',
      title: 'Map Feedback',
      actualQuestion: 'Is the map useful?',
      positiveQuestion: 'What do you like about the map?',
      negativeQuestion: 'What issue do you have with the map?',
      includeCheckbox: true,
      tags: ['shipment-map'],
      onFeedbackSent: () => this.sendEvent({ event: Events.FeedbackSent }),
      onFeedbackOpened: () => this.sendEvent({ event: Events.FeedbackClicked }),
      onFeedbackClosed: () => this.sendEvent({ event: Events.FeedbackClosed }),
    });
  }

  @action
  onLoad(leafletEvent: LeafletEvent) {
    const leafletInstance = leafletEvent.target;
    this.leafletMapInstance = leafletInstance;

    // @ts-ignore
    window.leafletMap = leafletInstance;
    // @ts-ignore
    window.addDebugMarker = ({ latitude, longitude, positions }) => {
      const _posistions = positions ?? [{ latitude, longitude }];
      for (const { latitude, longitude } of _posistions) {
        leaflet.marker([latitude, longitude]).addTo(leafletInstance);
      }
    };

    this.sendEvent({
      event: Events.MapLoadSucceeded,
    });

    leafletInstance.on('dragend', () => {
      this.sendEvent({ event: Events.ViewDragged });
    });

    leafletInstance.on('zoomstart', (leafletEvent: LeafletEvent) => {
      this.lastZoomLevel = leafletEvent.target.getZoom();
    });

    leafletInstance.on('zoomend', (leafletEvent: LeafletEvent) => {
      const { lastZoomLevel } = this;

      if (leafletEvent.target.getZoom() > lastZoomLevel) {
        this.sendEvent({ event: Events.ViewZoomedIn });
      } else {
        this.sendEvent({ event: Events.ViewZoomedOut });
      }

      this.lastZoomLevel = leafletEvent.target.zoom;
    });
  }

  @action
  onJourneyMarkerAdded() {
    this.drawOnMap.perform();
  }

  sendEvent = this.args.journey.mapDataLoader.sendEvent;

  resizeMap = task({ restartable: true }, async () => {
    await timeout(isTesting() ? 1 : 100);
    this.leafletMapInstance?.invalidateSize({ animate: true });
    await timeout(isTesting() ? 1 : 100);
    this.zoomToTargetJourney();
  });

  drawOnMap = task({ restartable: true }, async () => {
    await timeout(isTesting() ? 1 : 200);
    this.drawWorlds();
    await this.resizeMap.perform();
  });

  drawVesselPositions = task({ maxConcurrency: 1 }, async (journeyPoint: JourneyPoint) => {
    if (journeyPoint.vesselJourney?.hasPositions) {
      this._drawVesselJourneyWithPositions(journeyPoint);
    }
  });

  zoomToTargetJourney() {
    if (!this.leafletMapInstance) {
      return;
    }
    const animationOptions = { animate: !isTesting(), maxZoom: 2 };
    const portOfLading = this.args.journey.journeyPoints.find((journeyPoint) => journeyPoint.isPortOfLading);
    const portOfDischarge = this.args.journey.journeyPoints.find((journeyPoint) => journeyPoint.isPortOfDischarge);

    if (portOfLading?.coordinates && portOfDischarge?.coordinates) {
      this.leafletMapInstance.fitBounds(
        [
          portOfLading.worlds.current.applyOffset(portOfLading.coordinates),
          portOfDischarge.worlds.current.applyOffset(portOfDischarge.coordinates),
        ],
        animationOptions,
      );
    }
  }

  drawWorlds() {
    const { journey } = this.args;
    for (const journeyPoint of journey.journeyPoints) {
      if (journeyPoint.isLast) {
        continue;
      }

      this.drawVesselPositions.perform(journeyPoint);
      const projectedPathBetweenPoints = projectedFutureJourneyPath(journeyPoint);
      this.leafletMapInstance &&
        leaflet
          .geoJson(projectedPathBetweenPoints.geojson, { style: projectedPathBetweenPoints.style, interactive: false })
          .addTo(this.leafletMapInstance);
    }
  }

  _drawVesselJourneyWithPositions(journeyPoint: JourneyPoint) {
    const completedTracks = completedVesselJourney(journeyPoint);
    this.leafletMapInstance &&
      leaflet
        .geoJson(completedTracks.geojson, { style: completedTracks.style, interactive: false })
        .addTo(this.leafletMapInstance);
  }

  @action
  toggleExpanded() {
    if (this.args.isExpanded) {
      this.sendEvent({ event: Events.ViewCollapsed });
    } else {
      this.sendEvent({ event: Events.ViewExpanded });
    }
    this.args.toggleExpanded();
  }

  @action
  initMap(element: HTMLElement) {
    this.leafletMapInstance = leaflet.map(element, { attributionControl: false });

    leaflet
      .tileLayer(this.tilesetsURL, {
        attribution: '',
      })
      .addTo(this.leafletMapInstance);

    // on load must be before setView
    this.leafletMapInstance.on('load', this.onLoad);
    this.leafletMapInstance.setView(this.mapCenterOn.center, this.mapCenterOn.zoom);
  }
}
