/* eslint-disable prettier/prettier */
import templateOnlyComponent from '@ember/component/template-only';
import { JourneyPoint } from '../../../../native/journey-point.ts';

interface MultiMapDetailsMarkersPortIconSignature {
  Element: HTMLDivElement;
  Args: {
    journeyPoint: JourneyPoint | null;
    faSize?: string;
    simple?: boolean;
    isHovered?: boolean;
  };
}

const MultiMapDetailsMarkersPortIconComponent = templateOnlyComponent<MultiMapDetailsMarkersPortIconSignature>();

export default MultiMapDetailsMarkersPortIconComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MultiMap::Markers::Port::Icon': typeof MultiMapDetailsMarkersPortIconComponent;
  }
}
