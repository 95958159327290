import templateOnlyComponent from '@ember/component/template-only';
import type { Icons } from './icon.ts';

interface ContainersMapTooltipSignature {
  Element: HTMLDivElement;
  Args: {
    icon: Icons;
    label?: string;
  };
  Blocks: {
    title: [];
    body: [];
  };
}

const ContainersMapTooltipComponent = templateOnlyComponent<ContainersMapTooltipSignature>();

export default ContainersMapTooltipComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TntMap::Tooltip': typeof ContainersMapTooltipComponent;
  }
}
