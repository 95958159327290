/* eslint-disable prettier/prettier */
// @ts-ignore
import { cached } from '@glimmer/tracking';
import { JourneyPoint } from './journey-point.ts';
import { MapDataLoader } from './map-data-loader.ts';
import { type RouteLocation } from './map-data.ts';

export class Journey {
  private rawJourneyPoints: JourneyPoint[] = [];
  private lastRouteLocation?: JourneyPoint;
  mapDataLoader: MapDataLoader;

  routeLocationCount = 0;

  constructor(mapDataLoader: MapDataLoader) {
    this.mapDataLoader = mapDataLoader;
  }

  @cached
  get journeyPoints() {
    return this.rawJourneyPoints.filter((journeyPoint) => {
      if (this.lastRouteLocation) {
        return journeyPoint.currentIndex <= this.lastRouteLocation.currentIndex;
      }

      return true;
    });
  }

  get route() {
    return this.mapDataLoader.data?.route;
  }

  get shipmentV2() {
    return this.mapDataLoader.data?.shipmentV2;
  }

  get canShowMap(): boolean {
    return Boolean(this.mapDataLoader.data) && this.mapDataLoader.isIdle;
  }

  get routeLocationJourneyPoints() {
    return this.journeyPoints;
  }

  get updatedAt() {
    return this.shipmentV2?.lineTrackingLastSucceededAt;
  }

  @cached
  get first() {
    return this.journeyPoints[0];
  }

  @cached
  get last() {
    return this.journeyPoints[this.journeyPoints.length - 1];
  }

  get isFinished(): boolean {
    return Boolean(
      this.routeLocationJourneyPoints.find((JourneyPoint) => JourneyPoint.isPortOfDischarge)?.inboundVesselArrived,
    );
  }

  add(journeyPoint: RouteLocation, index: number) {
    this.lastRouteLocation = new JourneyPoint(this, journeyPoint, index, ++this.routeLocationCount);
    this.rawJourneyPoints.push(this.lastRouteLocation);
  }
}
