/* eslint-disable prettier/prettier */
import type * as GeoJSON from 'geojson';
import { JourneyPoint } from '../journey-point.ts';

interface PortProperties {
  name: string;
  eta?: string;
  ata?: string;
  etd?: string;
  lastUpdateTimestamp?: string;
  index: number;
}

export type PortFeature = GeoJSON.Feature<GeoJSON.Point, PortProperties>;
export type VesselFeature = GeoJSON.Feature<GeoJSON.Point, null>;

export const portLocation = (journeyPoint: JourneyPoint): PortFeature => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: journeyPoint.worlds.current.applyGeojsonOffset(journeyPoint.geojsonCoordinates as [number, number]),
    },
    properties: {
      name: journeyPoint.location.name,
      lastUpdateTimestamp: journeyPoint.updatedAt,
      index: journeyPoint.currentIndex,
    },
  };
};

export const vesselLocation = (journeyPoint: JourneyPoint): VesselFeature => {
  let coordinates: GeoJSON.Point['coordinates'] = [];

  if (journeyPoint.vesselGeojsonCoordinates) {
    let vesselWorld = journeyPoint.worlds.next || journeyPoint.worlds.current;

    if (
      journeyPoint.journeyPointCrossing?.futurePositionsData.isCrossingAntiMeridian &&
      !journeyPoint.journeyPointCrossing.positionsData.isCrossingAntiMeridian
    ) {
      vesselWorld = journeyPoint.worlds.current;
    }

    coordinates = vesselWorld.applyGeojsonOffset(journeyPoint.vesselGeojsonCoordinates);
  }

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates,
    },
    properties: null,
  };
};
