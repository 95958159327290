/* eslint-disable prettier/prettier */
// Leaflet map by default draws points within coordinates that are between [-90, -180] and [90, 180]
// The edge coordinates together sum to 180 degrees latitude and 360 degrees longitude.
//
// When Leaflet hits an edge point it attempst to wrap the coordinates
// The bounds are reasonable however they'll make the map look weird when you attemt to
// go from Asia to North America and as a result cross the 180th meridian (antimeridian) point.
// In this scenario the expectation is to draw markers going from mid 'World' to 'East' world.
// Leaflet doesn't know that however, because negative degrees are on left hand side.
// Leaflet is capable of drawing points beyond the 360 degrees bounds, but in order to do that,
// the coordinates must be offset by us. This is the purpose of this module.

import { action } from '@ember/object';
import { humanizeDegrees, isAntimeridianCrossing } from '../native/utils.ts';
// @turf/ dependency is currently patched wiht pnpm patch because of incorrect type declarations
// v7.0.0 should fix it. Eventually @turf/turf@7.0.0-alpha.2 should work.
import { bearingToAzimuth, point } from '@turf/helpers';
import bearing from '@turf/bearing';
import { Journey } from './journey.ts';
import { JourneyPoint } from './journey-point.ts';
import { World } from './world.ts';
import { Position } from './vessel-journey.ts';
import { MapDataLoader } from './map-data-loader.ts';
import { VesselJourney } from './vessel-journey.ts';
// @ts-ignore
import { cached } from '@glimmer/tracking';
import { type Vessel } from './map-data.ts';

type FutureVesselJourneys = Record<string, VesselJourney>;

interface PositionsData {
  offset: number;
  crossedIndex: number | null;
  isCrossingAntiMeridian: boolean;
  westCount: number;
  eastCount: number;
}

export interface JourneyPointCrossing {
  journeyPoint: JourneyPoint;
  positionsData: PositionsData;
  futurePositionsData: PositionsData;
}

// Universe is meant to be a manager of data used for rendering on a map.
// It's role is to be THE structure when rendering journeyPoints and related data.
// It contains Worlds which it distributes journeyPoints to for rendering.
export class Universe {
  journey: Journey;
  worlds: World[];
  journeyPointCrossing: JourneyPointCrossing | null = null;
  mapDataLoader: MapDataLoader;

  // Going from China to North America
  // when crossing antimeridian draw NA point on the right side.
  eastWorld: World;
  // Not crossing
  mainWorld: World;
  // Going from North America to China
  // when crossing antimeridian draw China point on the left side.
  westWorld: World;

  constructor(journey: Journey) {
    this.journey = journey;
    this.mapDataLoader = journey.mapDataLoader;

    this.eastWorld = new World(this, 360);
    this.mainWorld = new World(this, 0);
    this.westWorld = new World(this, -360);
    this.worlds = [this.eastWorld, this.mainWorld, this.westWorld];
  }

  @cached
  get futureVesselJourneysByPort(): FutureVesselJourneys {
    const vesselJourneys: FutureVesselJourneys = {};
    if (!this.mapDataLoader.data?.futureVesselPositions) {
      return vesselJourneys;
    }

    for (const positionsBetween of this.mapDataLoader.data.futureVesselPositions) {
      if (positionsBetween && positionsBetween.positions.length > 0) {
        const id = positionsBetween.fromId;
        const existingFutureJourney = vesselJourneys[id];

        if (existingFutureJourney) {
          vesselJourneys[id] = new VesselJourney([...existingFutureJourney.positions, ...positionsBetween.positions]);
        } else {
          vesselJourneys[id] = new VesselJourney(positionsBetween.positions);
        }
      }
    }

    return vesselJourneys;
  }

  get isFutureRoutingDataAvailable(): boolean {
    const futureVesselJourneys = this.mapDataLoader.data?.futureVesselPositions;

    if (!futureVesselJourneys) {
      return false;
    }

    const missingVessel = this.journey.routeLocationJourneyPoints
      .filter((journeyPoint) => !journeyPoint.isPortOfDischarge)
      .some((journeyPoint) => !journeyPoint.vessel);

    return (
      futureVesselJourneys.length > 0 &&
      !missingVessel &&
      !futureVesselJourneys
        .filter((futureJourney) => !futureJourney.isJourneyLegFinished)
        .some((positionsBetween) => {
          return positionsBetween.positions.length === 0;
        })
    );
  }

  // looks up a journeyPoint with vessel that has positions
  // if the vessel crosses the antimeridian
  // the next journeyPoint will receive the vessel information
  distributeJourneyPointsBetweenWorlds() {
    const journeyPointCrossing = this.findJourneyPointThatCrossesAntimeridian();

    if (!journeyPointCrossing?.journeyPoint) {
      this.journey.journeyPoints.forEach((journeyPoint) => this.mainWorld.journeyPoints.push(journeyPoint));

      return;
    }

    let journeyPoint = this.journey.first;
    let previousOffset;
    while (journeyPoint) {
      const info = journeyPoint === journeyPointCrossing.journeyPoint ? journeyPointCrossing : null;
      const infoOffset = info?.positionsData?.offset || info?.futurePositionsData?.offset;

      const offset = previousOffset || 0;
      if (offset === -360) {
        this.westWorld.journeyPoints.push(journeyPoint);
      } else if (offset === 360) {
        this.eastWorld.journeyPoints.push(journeyPoint);
      } else if (offset === 0) {
        this.mainWorld.journeyPoints.push(journeyPoint);
      }

      if (!previousOffset && infoOffset) {
        previousOffset = infoOffset;
      }

      journeyPoint = journeyPoint.next || undefined;
    }

    this.journeyPointCrossing = journeyPointCrossing;
  }

  findJourneyPointThatCrossesAntimeridian(): JourneyPointCrossing | null {
    for (const journeyPoint of this.journey.journeyPoints) {
      const positionsBetween = journeyPoint.vesselPositionsBetweenThisAndNextPoint;
      const futurePositionsBetween = journeyPoint.futureVesselPositionsBetweenThisAndNextPoint;

      if (positionsBetween.length > 0 || futurePositionsBetween.length > 0) {
        const data = this.determineTargetWorld(journeyPoint, positionsBetween);
        const futureData = this.determineTargetWorld(journeyPoint, futurePositionsBetween);
        if (data.isCrossingAntiMeridian || futureData.isCrossingAntiMeridian) {
          return {
            journeyPoint,
            positionsData: data,
            futurePositionsData: futureData,
          };
        }
      }
    }

    return null;
  }

  // iterate over all positions
  // if antimeridian is crossed, stop iterating
  // if antimeridian is crossed then calculate bearing
  // if bearing repeatedly is either WEST or EAST then determine offset
  determineTargetWorld(_journeyPoint: JourneyPoint, positions: Position[]) {
    let isCrossingAntiMeridian: boolean = false;
    let crossedIndex: number | null = null;

    let westCount = 0;
    let eastCount = 0;

    // @TODO(bobrimperator)
    // should we define some kind of cutoff point for positions?
    // e.g. start counting directions only after 130 longitude
    positions.forEach((currentPosition, index) => {
      const previousPosition = positions[index - 1];

      if (previousPosition && previousPosition.coordinates[0]) {
        const point1 = previousPosition.coordinates;
        const point2 = currentPosition.coordinates;

        if (!crossedIndex) {
          isCrossingAntiMeridian = isAntimeridianCrossing(point1, point2);
          if (isCrossingAntiMeridian) {
            crossedIndex = index;
          }
        }
        const heading = humanizeDegrees(
          bearingToAzimuth(
            bearing(point(previousPosition.geojsonCoordinates), point(currentPosition.geojsonCoordinates)),
          ),
        );
        if (heading?.toLowerCase().includes('west')) {
          westCount += 1;
        }
        if (heading?.toLowerCase().includes('east')) {
          eastCount += 1;
        }
      }
    });

    let offset;

    if (isCrossingAntiMeridian && westCount > eastCount) {
      offset = -360;
    } else if (isCrossingAntiMeridian && eastCount > westCount) {
      offset = 360;
    } else {
      offset = 0;
    }

    return {
      offset,
      crossedIndex,
      isCrossingAntiMeridian,
      westCount,
      eastCount,
    };
  }

  @action
  findWorldByJourneyPoint(journeyPoint: JourneyPoint) {
    return this.worlds.find((world) => world.journeyPoints.includes(journeyPoint));
  }

  findJourneyPointByVessel(vessel: Vessel) {
    return this.journey.journeyPoints.find((journeyPoint) => journeyPoint.vessel === vessel);
  }
}
