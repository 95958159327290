/* eslint-disable prettier/prettier */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import leaflet, { type Map } from 'leaflet';
import { portLocation, type PortFeature } from '../../../native/geojson/locations.ts';
import { JourneyPoint } from '../../../native/journey-point.ts';
import ContainersMapComponent from '../../tnt-map.ts';
import { Events, type LocationTypes } from '../../../native/tracking.ts';

interface MapMarkersPortSignature {
  Args: {
    journeyPoint: JourneyPoint;
    leafletMapInstance: Map;
    onAdd: () => void;
    sendEvent: ContainersMapComponent['sendEvent'];
  };
}

interface IconOffsets {
  iconAnchor: [number, number];
  tooltipOffset: [number, number];
  iconSize?: [number, number];
}

export default class MapMarkersPortComponent extends Component<MapMarkersPortSignature> {
  @tracked isTooltipHovered = false;

  get isTooltipExpanded() {
    return this.isTooltipHovered;
  }

  get iconOffsets(): IconOffsets {
    const journeyPoint = this.args.journeyPoint;
    if (journeyPoint.isEdgeLocation) {
      return {
        iconAnchor: [23, 40],
        tooltipOffset: [15, -23],
      };
    } else if (journeyPoint.isRouteLocation) {
      return {
        iconAnchor: [7, 18],
        tooltipOffset: [8, -7],
      };
    }

    return {
      iconAnchor: [0, 0],
      tooltipOffset: [0, 0],
    };
  }

  tooltipWrapperElement = window.document.createElement('div');
  tooltipLayer = leaflet
    .tooltip({
      interactive: true,
      offset: this.iconOffsets.tooltipOffset,
      className: 'tntm__tooltips-port',
    })
    .setContent(this.tooltipWrapperElement);
  iconElement = window.document.createElement('div');

  constructor(owner: unknown, args: MapMarkersPortSignature['Args']) {
    super(owner, args);

    leaflet
      .geoJSON(portLocation(this.args.journeyPoint), {
        onEachFeature: this.onEachFeature,
      })
      .addTo(this.args.leafletMapInstance);
    this.args.onAdd();
  }

  @action
  onEachFeature(_feature: PortFeature, layer: leaflet.Layer) {
    if (layer instanceof leaflet.Marker) {
      layer.setIcon(
        leaflet.divIcon({
          html: this.iconElement,
          iconAnchor: this.iconOffsets.iconAnchor,
          iconSize: this.iconOffsets.iconSize,
        }),
      );
      layer.bindTooltip(this.tooltipLayer);

      layer.addEventListener('mouseover', this.expandTooltip, this);
      layer.addEventListener('mouseout', this.collapseTooltip, this);
      layer.addEventListener('click', this.markerClicked, this);
    }
    layer.addTo(this.args.leafletMapInstance);
  }

  get eventLocationType(): LocationTypes {
    if (this.args.journeyPoint.isPortOfLading) {
      return 'port_of_lading';
    }
    if (this.args.journeyPoint.isPortOfDischarge) {
      return 'port_of_discharge';
    }
    return 'transshipment';
  }

  @action
  markerClicked() {
    this.args.sendEvent({
      event: Events.LocationClicked,
      location_type: this.eventLocationType,
    });
  }

  @action
  expandTooltip() {
    this.isTooltipHovered = true;
    this.args.sendEvent({
      event: Events.LocationHovered,
      location_type: this.eventLocationType,
    });
  }

  @action
  collapseTooltip() {
    this.isTooltipHovered = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TntMap::Markers::Port': typeof MapMarkersPortComponent;
  }
}
