/* eslint-disable prettier/prettier */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import leaflet, { type Layer, type Map } from 'leaflet';
import { portLocation, type PortFeature } from '../../../native/geojson/locations.ts';
import { JourneyPoint } from '../../../native/journey-point.ts';

interface MultiMapMarkersPortSignature {
  Args: {
    journeyPoint: JourneyPoint;
    leafletMapInstance: Map;
    onAdd: () => void;
  };
}

interface IconOffsets {
  iconAnchor: [number, number];
  tooltipOffset: [number, number];
  iconSize?: [number, number];
}

export default class MultiMapMarkersPortComponent extends Component<MultiMapMarkersPortSignature> {
  @tracked isTooltipHovered = false;

  get isTooltipExpanded() {
    return this.isTooltipHovered;
  }

  get iconOffsets(): IconOffsets {
    const journeyPoint = this.args.journeyPoint;
    if (journeyPoint.isEdgeLocation) {
      return {
        iconAnchor: [23, 40],
        tooltipOffset: [15, -23],
      };
    } else if (journeyPoint.isRouteLocation) {
      return {
        iconAnchor: [7, 18],
        tooltipOffset: [8, -7],
      };
    }

    return {
      iconAnchor: [0, 0],
      tooltipOffset: [0, 0],
    };
  }

  tooltipWrapperElement = window.document.createElement('div');
  tooltipLayer = leaflet
    .tooltip({
      interactive: true,
      offset: this.iconOffsets.tooltipOffset,
      className: 'tntm__tooltips-port',
    })
    .setContent(this.tooltipWrapperElement);
  iconElement = window.document.createElement('div');

  layer: Layer;

  constructor(owner: unknown, args: MultiMapMarkersPortSignature['Args']) {
    super(owner, args);

    this.layer = leaflet
      .geoJSON(portLocation(this.args.journeyPoint), {
        onEachFeature: this.onEachFeature,
      })
      .addTo(this.args.leafletMapInstance);

    this.args.onAdd();
  }

  willDestroy(): void {
    super.willDestroy();
    this.layer.remove();
  }

  onEachFeature = (_feature: PortFeature, layer: leaflet.Layer) => {
    if (layer instanceof leaflet.Marker) {
      layer.setIcon(
        leaflet.divIcon({
          html: this.iconElement,
          iconAnchor: this.iconOffsets.iconAnchor,
          iconSize: this.iconOffsets.iconSize,
        }),
      );
      layer.bindTooltip(this.tooltipLayer);

      layer.addEventListener('mouseover', this.expandTooltip, this);
      layer.addEventListener('mouseout', this.collapseTooltip, this);
      layer.addEventListener('click', this.markerClicked, this);
    }
    layer.addTo(this.args.leafletMapInstance);
  };

  markerClicked = () => {};

  expandTooltip = () => {
    this.isTooltipHovered = true;
  };

  collapseTooltip = () => {
    this.isTooltipHovered = false;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MultiMap::Markers::Port': typeof MultiMapMarkersPortComponent;
  }
}
