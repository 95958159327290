/* eslint-disable prettier/prettier */
import { JourneyPoint } from './journey-point.ts';
import { Universe } from './universe.ts';

// World is a simple wrapper for journeyPoints it received from the Universe.
// It contains an offset it needs to apply to received coordinates.
// It doesn't mutate the data directly however, the data is expected to be long-lived objects,
// that we shouldn't taint.
export class World {
  offset: number;
  universe: Universe;
  journeyPoints: JourneyPoint[] = [];

  constructor(universe: Universe, offset: number) {
    this.offset = offset;
    this.universe = universe;
  }

  applyOffset = ([lat, lng]: [number, number]): [number, number] => {
    return [lat, lng + this.offset];
  };

  applyGeojsonOffset = ([lng, lat]: [number, number]): [number, number] => {
    return [lng + this.offset, lat];
  };
}
