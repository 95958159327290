import { allSettled, task } from 'ember-concurrency';
import { JourneyPoint } from './journey-point.ts';
import { Journey } from './journey.ts';
import { MapData, type ContainerRoute, type Shipment, type Vessel } from './map-data.ts';
import { Events, type EventPayload } from './tracking.ts';
import { Universe } from './universe.ts';
import { VesselJourney } from './vessel-journey.ts';

type PositionsBetweenPorts = {
  positions: any[];
  fromId: string;
  toId: string;
  vesselId: string;
  isJourneyLegFinished: boolean;
};

interface MapDataLoaderData {
  shipmentV2: Shipment;
  containerId: string;
  route: ContainerRoute;
  journey: Journey;
  universe: Universe;
  futureVesselPositions: PositionsBetweenPorts[];
}

interface InitializeOptions {
  disableAnalytics: boolean;
  apiHost: string;
  authToken: string;
  accountId: string;
  viewSource: string;
  maptilerKey: string;
  showAttribution?: boolean;
}

export interface MapConfig {
  maptilerKey: string;
  showAttribution: boolean;
}

interface LoadPositionsParams {
  vessel: Vessel;
  vesselCoordinates: [number, number] | null;
  fromId: string;
  toId: string;
  isJourneyLegFinished: boolean;
}

interface VesselPositions {
  vessel: Vessel;
  vesselJourney: VesselJourney;
}

const createPositionsBetweenParams = (
  journeyPoints: JourneyPoint[],
  pastVesselPositions: VesselPositions[],
): LoadPositionsParams[] => {
  const params: LoadPositionsParams[] = [];
  let lastValidJourneyPoint: JourneyPoint | undefined;
  for (const journeyPoint of journeyPoints) {
    if (!journeyPoint.nextRouteLocation) {
      continue;
    }

    if (journeyPoint.vessel && journeyPoint.location.id && journeyPoint.nextRouteLocation.location.id) {
      const pastPositions = pastVesselPositions.find(({ vessel }) => vessel.id === journeyPoint.vessel?.id);
      const lastCoordinates = pastPositions?.vesselJourney.last?.coordinates;

      params.push({
        vessel: journeyPoint.vessel,
        vesselCoordinates: lastCoordinates || null,
        fromId: journeyPoint.location.id,
        toId: journeyPoint.nextRouteLocation.location.id,
        isJourneyLegFinished: journeyPoint.isJourneyLegFinished,
      });
      lastValidJourneyPoint = journeyPoint;
    } else if (lastValidJourneyPoint && lastValidJourneyPoint.vessel) {
      params.push({
        vessel: lastValidJourneyPoint.vessel,
        vesselCoordinates: null,
        fromId: journeyPoint.location.id,
        toId: journeyPoint.nextRouteLocation.location.id,
        isJourneyLegFinished: journeyPoint.isJourneyLegFinished,
      });
    }
  }
  return params;
};

interface Services {
  metrics?: {
    trackEvent: (event: EventPayload) => void;
  };
  modals?: {
    open: (name: string, content?: {}) => void;
  };
}

export class MapDataLoader {
  services: Services;
  mapData: MapData;
  config: MapConfig;

  disableAnalytics: boolean;
  viewSource: string;

  pastVesselPositions: VesselPositions[] = [];

  constructor(options: InitializeOptions, services?: Services) {
    if (!options) {
      throw new Error('Map options must be provided!');
    }

    this.disableAnalytics = Boolean(options.disableAnalytics);
    this.viewSource = options.viewSource;

    this.mapData = new MapData({
      apiHost: options.apiHost,
      authToken: options.authToken,
      accountId: options.accountId,
    });

    this.config = {
      maptilerKey: options.maptilerKey,
      showAttribution: options.showAttribution ?? true,
    };

    this.services = services || {};
  }

  get data(): MapDataLoaderData | null | undefined {
    return this.loadShipmentDetailsTask.lastSuccessful?.value;
  }

  get isIdle() {
    return this.loadShipmentDetailsTask.isIdle;
  }

  get isRunning() {
    return this.loadShipmentDetailsTask.isRunning;
  }

  get isFailed() {
    return this.loadShipmentDetailsTask.last?.isError;
  }

  // ATA - Actual Time of Arrival
  // ETA - Estimated Time of Arrival
  // ATD - Actual Time of Departure
  // ETD - Estimated Time of Departure
  // TODO: This doesn't seem to be used anywhere
  get sortedRouteLocations() {
    const routeLocations = this.data?.route.routeLocations || [];

    if (routeLocations) {
      return routeLocations.sort?.((a, b) => {
        const aDate = a.outboundAtdAt || a.inboundAtaAt || a.outboundEtdAt || a.inboundEtaAt;
        const bDate = b.outboundAtdAt || b.inboundAtaAt || b.outboundEtdAt || b.inboundEtaAt;
        return new Date(aDate).getTime() - new Date(bDate).getTime();
      });
    } else {
      return [];
    }
  }

  get journey() {
    return this.data?.journey;
  }

  get universe() {
    return this.data?.universe as Universe;
  }

  get portOfLading() {
    return this.journey?.journeyPoints.find((jp) => jp.isPortOfLading)?.location;
  }

  get portOfDischarge() {
    return this.journey?.journeyPoints.find((jp) => jp.isPortOfDischarge)?.location;
  }

  get allVesselPositions() {
    const pastVesselPositions = this.pastVesselPositions
      .map(({ vesselJourney }) =>
        vesselJourney.positions.map((p) => ({
          latitude: p.latitude,
          longitude: p.longitude,
        })),
      )
      .flat();

    const futureVesselPositions = (this.data?.futureVesselPositions || []).map(({ positions }) => positions).flat();

    return [...pastVesselPositions, ...futureVesselPositions];
  }

  // Parameterizes loading required Vessel tracking data based on the context.
  // "Legacy" shipment/details route will have cargos, while the new views will use containers.
  // Only one of container or cargo options should exist and preferably never both.
  loadShipmentDetailsTask = task(
    {},
    async (_shipmentId: string, { containerId }: { containerId: string }): Promise<MapDataLoaderData | null> => {
      const vesselRoute = await this.mapData.loadContainerRoute(containerId);
      const journeyPoints = vesselRoute.routeLocations || [];
      const sortedJourneyPoints = journeyPoints.sort?.((a, b) => {
        const aDate = a.outboundAtdAt || a.inboundAtaAt || a.outboundEtdAt || a.inboundEtaAt;
        const bDate = b.outboundAtdAt || b.inboundAtaAt || b.outboundEtdAt || b.inboundEtaAt;
        return new Date(aDate).getTime() - new Date(bDate).getTime();
      });

      const journey = new Journey(this);
      sortedJourneyPoints.forEach((journeyPoint, index) => journey.add(journeyPoint, index));

      const universe = new Universe(journey);

      const pastVesselPositions: VesselPositions[] = [];

      try {
        const vesselPositions = await allSettled(
          journey.routeLocationJourneyPoints
            .filter((journeyPoint) => journeyPoint.vessel?.id)
            .map((journeyPoint) => {
              const from = journeyPoint.model.outboundAtdAt;
              const to = journeyPoint.nextRouteLocation?.date ?? new Date(vesselRoute.shipment.podEtaAt).toISOString();

              return this.loadVesselPositionsTask.perform(journeyPoint.vessel!.id, from, to);
            }),
        );

        for (const settled of vesselPositions) {
          const value = (settled as any)?.value;

          if (value) {
            pastVesselPositions.push(value);
          }
        }
      } catch (error) {
        console.error('Error while loading vessel positions: ' + error);
      }

      // pastVesselPositions must be available before futureVesselPositions begin to load
      // reason being that we rely on past positions to determine whether a journey leg is finished or not.
      this.pastVesselPositions = pastVesselPositions;
      const futureVesselPositions: PositionsBetweenPorts[] = await this.loadFuturePositions.perform(
        journey.routeLocationJourneyPoints,
        this.pastVesselPositions,
      );

      return {
        shipmentV2: vesselRoute.shipment,
        containerId,
        route: vesselRoute,
        journey,
        universe,
        futureVesselPositions,
      };
    },
  );

  // @TODO(bobrimperator): revisit tests for this
  // Some fixtures return vessel positions with the whole Route response, which is incorrect.
  loadVesselPositionsTask = task(
    {},
    async (vesselId: string | null, outboundAtdAt: string, podEtaAt: string): Promise<VesselPositions | null> => {
      // don't load positions when ship hasn't departured
      if (!(outboundAtdAt && vesselId)) {
        return null;
      }

      console.log('Loading vessel positions for vessel id: ' + vesselId, outboundAtdAt, podEtaAt);
      try {
        const show_positions = {
          from_timestamp: outboundAtdAt,
          to_timestamp: podEtaAt,
        };

        const vessel = await this.mapData.loadVessel(vesselId, show_positions);

        const vesselJourney = new VesselJourney(vessel.positions);

        return {
          vessel,
          vesselJourney,
        };
      } catch (error) {
        console.error('Error loading past positions for vessel id: ' + vesselId + error);
        return null;
      }
    },
  );

  loadFuturePositionsBetweenPortsTask = task(
    {},
    async (params: LoadPositionsParams): Promise<PositionsBetweenPorts> => {
      const { fromId, toId, vessel, vesselCoordinates, isJourneyLegFinished } = params;
      try {
        if (isJourneyLegFinished) {
          throw new Error(`${vessel.id} has past positions and is not shown on the map`);
        }

        const vesselWithFuturePositions = await (vesselCoordinates
          ? this.mapData.loadFutureVesselPositionsWithCoordinates(vessel.id, {
              latitude: vesselCoordinates[0].toString(),
              longitude: vesselCoordinates[1].toString(),
              port_id: toId,
              previous_port_id: fromId,
            })
          : this.mapData.loadFutureVesselPositions(vessel.id, {
              port_id: toId,
              previous_port_id: fromId,
            }));

        return {
          vesselId: vesselWithFuturePositions.id,
          positions: vesselWithFuturePositions.positions || [],
          fromId,
          toId,
          isJourneyLegFinished,
        };
      } catch (error) {
        console.error('Error loading positions for vessel id: ' + error);
        return {
          vesselId: vessel.id,
          positions: [],
          fromId,
          toId,
          isJourneyLegFinished,
        };
      }
    },
  );

  loadFuturePositions = task(
    {},
    async (journeyPoints: JourneyPoint[], pastVesselPositions: VesselPositions[]): Promise<PositionsBetweenPorts[]> => {
      const vesselJourneys: PositionsBetweenPorts[] = [];

      const params = createPositionsBetweenParams(journeyPoints, pastVesselPositions);

      try {
        const positionsBetweenPorts = await allSettled(
          params
            .map((params) => {
              if (params.vessel && params.toId) {
                return this.loadFuturePositionsBetweenPortsTask.perform(params);
              }
            })
            .filter(Boolean),
        );

        for (const settled of positionsBetweenPorts) {
          const value = (settled as any)?.value;

          if (value) {
            vesselJourneys.push(value);
          }
        }
      } catch (error) {
        console.error('Error while loading future positions between ports ' + error, error);
      }
      return vesselJourneys;
    },
  );

  sendEvent = (payload: EventPayload) => {
    if (this.disableAnalytics) {
      return;
    }

    const event = Object.assign(
      {
        shipment_id: this.data?.shipmentV2.id,
        container_id: this.data?.containerId,
        view_context: this.viewSource,
      },
      payload,
    );

    this.services.metrics?.trackEvent(event);
  };

  mapLoadFailed = () => {
    if (this.loadShipmentDetailsTask.performCount > 0 && this.loadShipmentDetailsTask.isIdle) {
      this.sendEvent({
        event: Events.MapLoadFailed,
      });
    }
  };
}
