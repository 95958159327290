/* eslint-disable prettier/prettier */
// @ts-ignore
import { cached } from '@glimmer/tracking';
import { Journey } from './journey.ts';
import { type JourneyPointCrossing } from './universe.ts';
import { World } from './world.ts';
import { type RouteLocation } from './map-data.ts';
import { VesselJourney } from './vessel-journey.ts';

export type JourneyPointModel = RouteLocation;

type JourneyLegVesselState = 'on-the-way' | 'visited';

interface Status {
  label: string;
  timestamp: string;
  timeZone: string;
}

export class JourneyPoint {
  model: JourneyPointModel;
  journey: Journey;
  currentIndex;
  routeLocationIndex;

  constructor(journey: Journey, model: JourneyPointModel, index: number, routeLocationIndex: number) {
    this.journey = journey;
    this.currentIndex = index;
    this.model = model;
    this.routeLocationIndex = routeLocationIndex;
  }

  @cached
  get previous(): JourneyPoint | null {
    const index = this.currentIndex - 1;

    if (index < 0) {
      return null;
    }

    return this.journey.journeyPoints[index] || null;
  }

  @cached
  get next(): JourneyPoint | null {
    const index = this.currentIndex + 1;

    if (index > this.journey.journeyPoints.length) {
      return null;
    }

    return this.journey.journeyPoints[index] || null;
  }

  @cached
  get previousRouteLocation(): JourneyPoint | null {
    let journeyPoint = this.previous;
    while (journeyPoint) {
      if (journeyPoint.model) {
        return journeyPoint;
      }

      journeyPoint = journeyPoint.previous;
    }

    return journeyPoint || null;
  }

  @cached
  get nextRouteLocation(): JourneyPoint | null {
    let journeyPoint = this.next;
    while (journeyPoint) {
      if (journeyPoint.model) {
        return journeyPoint;
      }

      journeyPoint = journeyPoint.next;
    }

    return journeyPoint || null;
  }

  @cached
  get portOfDischarge(): JourneyPoint | null {
    let journeyPoint = this.nextRouteLocation;
    while (journeyPoint) {
      if (journeyPoint.model) {
        return journeyPoint;
      }

      journeyPoint = journeyPoint.nextRouteLocation;
    }

    return journeyPoint || null;
  }

  get isLast() {
    return !this.next;
  }

  get isFirstRouteLocation() {
    return !this.previousRouteLocation;
  }

  get isPortOfDischarge(): boolean {
    if (this.model) {
      return !this.nextRouteLocation;
    } else {
      return false;
    }
  }

  get isPortOfLading(): boolean {
    if (this.model) {
      return Boolean(this.isFirstRouteLocation && this.nextRouteLocation);
    } else {
      return false;
    }
  }

  get label() {
    if (this.isFirstRouteLocation) {
      return 'POL';
    } else if (this.isLast || !this.nextRouteLocation) {
      return 'POD';
    } else {
      return `TS${this.routeLocationIndex - 1}`; // Transshipment
    }
  }

  get location() {
    return this.model.location;
  }

  // Note: inboundAtaAt doesn't exist for POL
  // Similarly outboundAtdAt doesn't exist for POD
  get inboundVesselArrived(): boolean {
    if (this.isPortOfLading) {
      return Boolean(this.next?.inboundVesselArrived);
    }

    return Boolean(this.model.inboundAtaAt);
  }

  get vesselIsIncomingToCurrentLocation() {
    if (this.isPortOfLading) {
      return false;
    }

    return !this.inboundVesselArrived;
  }

  get inboundVessel() {
    if (this.model) {
      return this.model.inboundVessel;
    }
    return null;
  }

  @cached
  get inboundVesselJourney(): VesselJourney | null {
    return (
      this.journey.mapDataLoader.pastVesselPositions.find((item) => {
        return item.vessel.id === this.vessel?.id;
      })?.vesselJourney || null
    );
  }

  get inboundVesselCoordinates(): [number, number] | null {
    if (this.inboundVesselJourney?.coordinates) {
      return this.inboundVesselJourney.coordinates;
    }
    return null;
  }

  get hasCoordinates() {
    return this.coordinates?.filter(Boolean).length === 2;
  }

  get coordinates(): [number, number] | null {
    if (this.location) {
      return [this.location.latitude, this.location.longitude];
    }

    return null;
  }

  get geojsonCoordinates(): [number, number] | null {
    if (this.location) {
      return [this.location.longitude, this.location.latitude];
    }

    return null;
  }

  get vesselGeojsonCoordinates(): [number, number] | null {
    const { vesselJourney } = this;
    if (vesselJourney?.last) {
      return vesselJourney.last.geojsonCoordinates;
    }
    return null;
  }

  get outboundVesselDeparted() {
    return Boolean(this.model.outboundAtdAt);
  }

  get vessel() {
    return this.model.outboundVessel;
  }

  @cached
  get vesselJourney(): VesselJourney | null {
    return (
      this.journey.mapDataLoader.pastVesselPositions.find((item) => {
        return item.vessel.id === this.vessel?.id;
      })?.vesselJourney || null
    );
  }

  get vesselVoyageNumber(): string | null {
    return this.model.outboundVoyageNumber;
  }

  get isRouteLocation() {
    return true;
  }

  get isEdgeLocation() {
    return this.isPortOfLading || this.isPortOfDischarge;
  }

  get canShowOutboundVessel() {
    const nextRouteLocation = this.nextRouteLocation;
    if (this.isLast || !this.vessel || nextRouteLocation?.isPortOfLading) {
      return false;
    }

    return Boolean(
      this.outboundVesselDeparted && !nextRouteLocation?.model.inboundAtaAt && this.vesselJourney?.hasPositions,
    );
  }

  get date() {
    return this.model.inboundAtaAt || this.model.outboundAtdAt || this.model.inboundEtaAt || this.model.outboundEtdAt;
  }

  @cached
  get vesselPositionsBetweenThisAndNextPoint() {
    const { vesselJourney, date } = this;
    const nextDate = this.nextRouteLocation?.date;

    if (vesselJourney?.hasPositions && nextDate && date && nextDate > date) {
      return vesselJourney.positions.filter((position) => {
        if (position.timestamp >= date && position.timestamp < nextDate) {
          return true;
        }
        return false;
      });
    } else {
      return [];
    }
  }

  @cached
  get futureVesselPositionsBetweenThisAndNextPoint() {
    const { location, journey } = this;
    const vesselJourney = location?.id ? journey.mapDataLoader.universe.futureVesselJourneysByPort[location.id] : null;

    if (vesselJourney?.hasPositions) {
      return vesselJourney.positions;
    } else {
      return [];
    }
  }

  @cached
  get firstPositionBetween() {
    return this.vesselPositionsBetweenThisAndNextPoint[0];
  }

  @cached
  get lastPositionBetween() {
    return this.vesselPositionsBetweenThisAndNextPoint[this.vesselPositionsBetweenThisAndNextPoint.length - 1];
  }

  @cached
  get worlds(): { current: World; next: World | null } {
    const { universe } = this.journey.mapDataLoader;

    const current = universe.findWorldByJourneyPoint(this) as unknown as World;
    const next = (this.next && universe.findWorldByJourneyPoint(this.next)) || null;
    return {
      current,
      next,
    };
  }

  get journeyPointCrossing(): JourneyPointCrossing | null {
    const { universe } = this.journey.mapDataLoader;
    return universe.journeyPointCrossing?.journeyPoint === this ? universe.journeyPointCrossing : null;
  }

  get updatedAt() {
    return this.journey.updatedAt;
  }

  get locationName() {
    if (this.location.countryCode === 'US') {
      return `${this.location.name}, ${this.location.stateAbbr}`;
    } else {
      return `${this.location.name}, ${this.location.countryCode}`;
    }
  }

  get journeyLegVesselState(): JourneyLegVesselState {
    if (this.inboundVesselArrived || this.outboundVesselDeparted) {
      return 'visited';
    }
    return 'on-the-way';
  }

  @cached
  get status(): Status {
    const timeZone = this.location.timeZone;
    if (this.model.outboundAtdAt) {
      return {
        label: 'Departed',
        timestamp: this.model.outboundAtdAt,
        timeZone,
      };
    } else if (this.model.inboundEtaAt) {
      return {
        label: 'ETA',
        timestamp: this.model.inboundEtaAt,
        timeZone,
      };
    } else if (this.model.outboundEtdAt) {
      return {
        label: 'ETD',
        timestamp: this.model.outboundEtdAt,
        timeZone,
      };
    } else {
      return {
        label: 'Arrived',
        timestamp: this.model.inboundAtaAt,
        timeZone,
      };
    }
  }

  get isJourneyLegFinished(): boolean {
    return Boolean(
      this.vesselPositionsBetweenThisAndNextPoint.length > 0 && this.nextRouteLocation?.inboundVesselArrived,
    );
  }
}
