/* eslint-disable prettier/prettier */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import leaflet, { type Map } from 'leaflet';
import { vesselLocation, type PortFeature } from '../../../native/geojson/locations.ts';
import { JourneyPoint } from '../../../native/journey-point.ts';
import { htmlSafe } from '@ember/template';
import ContainersMapComponent from '../../tnt-map.ts';
import { Events } from '../../../native/tracking.ts';
import { humanizeDegrees } from '../../../native/utils.ts';
import { bearingToAzimuth, point } from '@turf/helpers';
import bearing from '@turf/bearing';
import { type Vessel } from '../../../native/map-data.ts';

interface MapMarkersVesselSignature {
  Args: {
    journeyPoint: JourneyPoint;
    vessel: Vessel;
    leafletMapInstance: Map;
    podOriginalEtaAt: string;
    originalEstimateTimeZone: string;
    sendEvent: ContainersMapComponent['sendEvent'];
  };
}

interface VesselStyles {
  colorFill: string;
  strokeColor: string;
}

export default class MapMarkersVesselComponent extends Component<MapMarkersVesselSignature> {
  @tracked isTooltipHovered = false;

  get vesselStyles(): VesselStyles {
    if (this.isTooltipHovered) {
      return {
        colorFill: 'var(--green-200)',
        strokeColor: 'var(--green-600)',
      };
    } else {
      return {
        colorFill: 'var(--green-100)',
        strokeColor: 'var(--green-600)',
      };
    }
  }

  get vesselRotationTransform() {
    return htmlSafe(`transform: rotate(${this.azimuthDegrees}deg)`);
  }

  // [+right | -left, +up | -down]
  get vesselAnchorOffset(): [number, number] {
    const heading = this.heading;

    // visit "vesel path centering" test for this :)
    switch (heading) {
      case 'North':
        return [15.5, 20];
      case 'East':
        return [16, 11];
      case 'South':
        return [24.75, 11];
      case 'West':
        return [24, 20];
      case 'North-East':
        return [13.5, 16];
      case 'South-East':
        return [27, 16.75];
      case 'North-West':
        return [16.75, 20];
      case 'South-West':
        return [20, 21];
      default:
        return [0, 0];
    }
  }

  get azimuthDegrees(): number {
    const [point1, point2] = this.args.journeyPoint.vesselPositionsBetweenThisAndNextPoint.slice(-2);

    if (point1 && point2) {
      return bearingToAzimuth(bearing(point(point1.geojsonCoordinates), point(point2.geojsonCoordinates)));
    }

    return 0;
  }

  get heading(): string {
    return humanizeDegrees(this.azimuthDegrees);
  }

  tooltipWrapperElement = window.document.createElement('div');
  tooltipLayer = leaflet
    .tooltip({
      className: 'tntm__tooltips-vessel',
      offset: [15, 0],
    })
    .setContent(this.tooltipWrapperElement);
  iconElement = window.document.createElement('div');
  iconLayer = leaflet.divIcon({
    html: this.iconElement,
    iconSize: [40, 40],
    iconAnchor: this.vesselAnchorOffset,
  });

  constructor(owner: unknown, args: MapMarkersVesselSignature['Args']) {
    super(owner, args);

    leaflet
      .geoJSON(vesselLocation(this.args.journeyPoint), {
        onEachFeature: this.onEachFeature,
      })
      .addTo(this.args.leafletMapInstance);
  }

  @action
  onEachFeature(_feature: PortFeature, layer: leaflet.Layer) {
    if (layer instanceof leaflet.Marker) {
      const icon = layer.setIcon(this.iconLayer);
      icon.bindTooltip(this.tooltipLayer);
      icon.addEventListener('mouseover', this.expandTooltip, this);
      icon.addEventListener('mouseout', this.collapseTooltip, this);
      icon.addEventListener('click', this.markerClicked, this);
    }

    layer.addTo(this.args.leafletMapInstance);
  }

  @action
  markerClicked() {
    this.args.sendEvent({
      event: Events.VesselClicked,
      vessel_id: this.args.journeyPoint.vessel?.id as any as string,
    });
  }

  @action
  expandTooltip() {
    this.isTooltipHovered = true;
    this.args.sendEvent({
      event: Events.VesselHovered,
      vessel_id: this.args.journeyPoint.vessel?.id as any as string,
    });
  }

  @action
  collapseTooltip() {
    this.isTooltipHovered = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TntMap::Markers::Vessel': typeof MapMarkersVesselComponent;
  }
}
