/* eslint-disable prettier/prettier */
import templateOnlyComponent from '@ember/component/template-only';

interface ContainersMapTooltipInfoLineSignature {
  Element: null;
  Args: {
    secondary?: boolean;
  };
  Blocks: {
    label: [];
    info: [];
  };
}

const ContainersMapTooltipInfoLineComponent = templateOnlyComponent<ContainersMapTooltipInfoLineSignature>();

export default ContainersMapTooltipInfoLineComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TntMap::Tooltip::InfoLine': typeof ContainersMapTooltipInfoLineComponent;
  }
}
