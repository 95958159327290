import Component from '@glimmer/component';

/**
 * @class HarborTabComponent
 * @param {string} colour
 * @param {{id: string, label: string, icon: string}[]} [tabs]
 * @param {string} currentTab
 * @function onChange
 */
export default class HarborTabComponent extends Component {
  get activeClasses() {
    if (this.args.colour === 'green') {
      return 'tw-border-green-500 tw-text-green-600';
    }
    return 'tw-border-blue-500 tw-text-blue-600';
  }
}
