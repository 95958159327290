/* eslint-disable prettier/prettier */
import Component from '@glimmer/component';

export interface TntHarborBadgeSignature {
  Element: HTMLSpanElement;
  Args: {
    color?: 'gray';
  };
  Blocks: {
    title: [];
  };
}
/**
 * @class BadgeComponent
 * @param {string} color gray | green | blue | red | yellow
 */
export default class TntHarborBadgeComponent extends Component<TntHarborBadgeSignature> {
  // In order to prevent Tailwind from purging the CSS classes, we need to
  // explicitly list them here.
  get colorClasses() {
    const { color } = this.args;
    switch (color) {
      case 'gray':
        return '--gray';
      default:
        return '--gray';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TntMap::Badge': typeof TntHarborBadgeComponent;
  }
}
