import templateOnlyComponent from '@ember/component/template-only';

interface HarborFeedVerticalLineSignature {
  Args: {
    isLastElement?: boolean;
  };
}

const HarborFeedVerticalLine =
  templateOnlyComponent<HarborFeedVerticalLineSignature>();

export default HarborFeedVerticalLine;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HarborFeedVerticalLine: typeof HarborFeedVerticalLine;
  }
}
