/* eslint-disable prettier/prettier */
enum Events {
  MapLoadSucceeded = 'map.load.succeeded',
  MapLoadFailed = 'map.load.failed',

  LocationHovered = 'map.location.hovered',
  LocationClicked = 'map.location.clicked',

  VesselHovered = 'map.vessel.hovered',
  VesselClicked = 'map.vessel.clicked',

  ViewZoomedIn = 'map.view.zoomed_in',
  ViewZoomedOut = 'map.view.zommed_out',

  ViewDragged = 'map.view.panned',

  ViewExpanded = 'map.view.expanded',
  ViewCollapsed = 'map.view.collapsed',

  FeedbackClicked = 'map.feedback.clicked',
  FeedbackSent = 'map.feedback.sent',
  FeedbackClosed = 'map.feedback.closed',
}

type GenericEventPayload<E extends Events> = {
  event: E;
  shipment_id?: string;
  container_id?: string;
  view_source?: 'shipment_details_sidebar' | 'shipment_details_page';
};

type LocationTypes = 'port_of_lading' | 'transshipment' | 'port_of_discharge';

type LocationEventPayload = GenericEventPayload<Events.LocationHovered | Events.LocationClicked> & {
  location_type: LocationTypes;
};

type VesselEventPayload = GenericEventPayload<Events.VesselHovered | Events.VesselClicked> & {
  vessel_id: string;
};

type ViewEventPayload = GenericEventPayload<
  Events.ViewDragged | Events.ViewZoomedIn | Events.ViewZoomedOut | Events.ViewExpanded | Events.ViewCollapsed
>;

type MapEventPayload = GenericEventPayload<Events.MapLoadFailed | Events.MapLoadSucceeded>;

type MapFeedbackPayload = GenericEventPayload<Events.FeedbackClicked | Events.FeedbackClosed | Events.FeedbackSent>;

type EventPayload = MapEventPayload | LocationEventPayload | VesselEventPayload | ViewEventPayload | MapFeedbackPayload;

export {
  type EventPayload,
  Events,
  type GenericEventPayload,
  type VesselEventPayload,
  type LocationEventPayload,
  type LocationTypes,
};
