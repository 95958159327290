export default {
  boolean: {
    from_url: (data) => {
      return data === 'true';
    },
    to_url: (data) => data?.toString(),
    to_api: (data) => data?.toString(),
  },

  'plain-text': {
    from_url: (data) => {
      return data;
    },

    to_url: (data) => {
      return data;
    },

    to_api: (data) => {
      return data;
    },
  },
  'boolean-always-true': {
    from_url: (data) => {
      return data === 'true';
    },
    to_url: (data) => data?.toString(),
    to_api: (data) => data?.toString(),
  },
  date: {
    from_url: (data) => {
      if (['@exists', '@not_exists'].includes(data)) {
        return data;
      }
      if (data[0] instanceof Date && data[1] instanceof Date) {
        return data?.split(',').map((d) => new Date(d));
      } else {
        return data;
      }
    },
    to_url: (data) => {
      if (['@exists', '@not_exists'].includes(data)) {
        return data;
      }

      if (data[0] instanceof Date && data[1] instanceof Date) {
        return data?.map((d) => d.toISOString().split('T')[0]).join(',');
      } else {
        return data;
      }
    },
    to_api: (data) => {
      if (['@exists', '@not_exists'].includes(data)) {
        return data;
      }

      if (data[0] instanceof Date && data[1] instanceof Date) {
        return [
          `>=${data[0].toISOString().split('T')[0]}`,
          `<=${data[1].toISOString().split('T')[0]}`,
        ];
      } else {
        return [`>=${data[0]}`, `<=${data[1]}`];
      }
    },
  },
  port: {
    from_url: (data) => {
      if (Array.isArray(data)) {
        return data;
      }
      return data?.split(',');
    },
    to_url: (data) => {
      return data?.map((d) => d.name || d).join(',');
    },
    to_api: (data) => {
      return data?.join(',');
    },
  },

  'port-with-query': {
    from_url: (data) => {
      if (Array.isArray(data)) {
        return data;
      }
      return data?.split(',');
    },
    to_url: (data) => {
      return data?.map((d) => d.name || d).join(',');
    },
    to_api: (data) => {
      return data?.join(',');
    },
  },

  tag: {
    from_url: (data) => {
      if (Array.isArray(data)) {
        return data;
      }
      return data?.split(',');
    },
    to_url: (data) => {
      return data?.map((d) => d.name || d).join(',');
    },
    to_api: (data) => {
      return data?.join(',');
    },
    addOrRemove: (urlValue, fromValue) => {
      let queryParams = urlValue.split(',');

      for (const value of fromValue) {
        const valueIndex = queryParams.indexOf(value);
        if (valueIndex > -1) {
          queryParams.splice(valueIndex, 1);
        } else {
          queryParams.push(value);
        }
      }

      return queryParams;
    },
  },

  scac: {
    from_url: (data) => {
      return data?.split(',');
    },
    to_url: (data) => {
      return data?.join(',');
    },
    to_api: (data) => {
      return data?.join(',');
    },
  },

  customer: {
    from_url: (data) => {
      return data?.split(',');
    },
    to_url: (data) => {
      return data?.map((d) => d.id || d).join(',');
    },
    to_api: (data) => {
      return data?.join(',');
    },
  },
  'vessel-name': {
    from_url: (data) => {
      return data?.split(',');
    },
    to_url: (data) => {
      return data?.map((d) => d.name || d).join(',');
    },
    to_api: (data) => {
      return data?.join(',');
    },
  },
  'pod-terminal': {
    from_url: (data) => {
      return data?.split(',');
    },
    to_url: (data) => {
      return data?.map((d) => d.id || d).join(',');
    },
    to_api: (data) => {
      return data?.join(',');
    },
  },
  text: {
    from_url: (data) => {
      return data;
    },

    to_url: (data) => {
      return data;
    },

    to_api: (data) => {
      return data;
    },
  },
  chip: {
    from_url: (data) => {
      return data;
    },

    to_url: (data) => {
      return data;
    },

    to_api: (data) => {
      return data;
    },
  },
};
