/* eslint-disable prettier/prettier */
import { waitFor } from '@ember/test-waiters';

interface ShipmentApiResponse {
  type: 'shipment';
  Attributes: {
    id: string;
    pod_eta_at: string;
    line_tracking_last_succeeded_at: string;
    pod_original_eta_at: string;
    pod_timezone: string;
  };
}

interface ApiPosition {
  latitude: number;
  longitude: number;
  timestamp: string;
  heading?: number;
  estimated: boolean;
}

interface VesselApiResponse {
  type: 'vessel';
  Attributes: {
    id: string;
    name: string;
    latitude: string;
    longitude: string;
    position_timestamp: string;
    positions: ApiPosition[];
  };
}

interface RouteLocationApiResponse {
  type: 'route_location';
  Attributes: {
    id: string;
    inbound_voyage_number: string;
    outbound_voyage_number: string;
    inbound_eta_at: string;
    inbound_ata_at: string;
    outbound_etd_at: string;
    outbound_atd_at: string;
  };
  Relationships: {
    route: JsonAPI<{ type: ContainerRouteApiResponse['type'] }>;
    location: JsonAPI<{ type: PortApiResponse['type'] }>;
    inbound_vessel: JsonAPI<{ type: VesselApiResponse['type'] }>;
    outbound_vessel: JsonAPI<{ type: VesselApiResponse['type'] }>;
  };
}

interface ContainerRouteApiResponse {
  type: 'route';
  Attributes: {
    id: string;
  };
  Relationships: {
    cargo: JsonAPI<{ type: 'container' }>;
    route_locations: JsonAPI<{ type: RouteLocationApiResponse['type'] }>;
  };
  Included: (
    | JsonAPI<PortApiResponse>['data']
    | JsonAPI<VesselApiResponse>['data']
    | JsonAPI<RouteLocationApiResponse>['data']
    | JsonAPI<ShipmentApiResponse>['data']
  )[];
}

interface PortApiResponse {
  type: 'port';
  Attributes: {
    id: string;
    name: string;
    code: string;
    state_abbr: string | null;
    city: string | null;
    country_code: string;
    latitude: string;
    longitude: string;
    time_zone: string;
  };
}

export interface Shipment {
  id: string;
  podEtaAt: string;
  lineTrackingLastSucceededAt: string;
  podOriginalEtaAt: string;
  podTimezone: string;
}
export interface Vessel {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
  positionTimestamp: string;
  positions: ApiPosition[];
  navigationalHeadingDegrees?: number;
  nauticalSpeedKnots?: number;
}

export interface Port {
  id: string;
  name: string;
  code: string;
  stateAbbr: string | null;
  city: string | null;
  countryCode: string;
  latitude: number;
  longitude: number;
  timeZone: string;
}

export interface RouteLocation {
  id: string;
  inboundVoyageNumber: string;
  outboundVoyageNumber: string;
  inboundEtaAt: string;
  inboundAtaAt: string;
  outboundEtdAt: string;
  outboundAtdAt: string;

  location: Port;
  inboundVessel: Vessel | null;
  outboundVessel: Vessel | null;
}
export interface ContainerRoute {
  id: string;
  routeLocations: RouteLocation[];
  shipment: Shipment;
}

interface JsonAPISignature {
  Attributes?: any;
  Included?: any;
  Relationships?: any;
  type: string;
}

interface JsonAPI<T extends JsonAPISignature> {
  data: {
    id: string;
    type: T['type'];
    attributes: T['Attributes'];
    relationships: T['Relationships'];
  };
  included: T['Included'];
}

interface RequestConfig {
  apiHost: string;
  authToken: string;
  accountId: string;
}

class UnauthorizedError extends Error {}
class FailedError extends Error {}

interface VesselPositionParams {
  port_id: string;
  previous_port_id: string;
}

interface VesselPositionWithCoordinatesParams {
  port_id: string;
  previous_port_id: string;
  latitude: string;
  longitude: string;
}

interface VesselPastPositionParams {
  from_timestamp: string;
  to_timestamp: string;
}

export class MapData {
  config: RequestConfig;

  constructor(config: RequestConfig) {
    this.config = config;
  }

  @waitFor
  async loadVessel(id: string, params?: VesselPastPositionParams) {
    const _params: { [key: string]: string } = {};

    if (params) {
      _params['show_positions[from_timestamp]'] = params.from_timestamp;
      _params['show_positions[to_timestamp]'] = params.to_timestamp;
    }
    const response = (await this.get(
      this.createUrl(`/v2/vessels/${id}`, _params),
    )) as unknown as JsonAPI<VesselApiResponse>;

    return {
      id: response.data.id,
      name: response.data.attributes.name,
      positions: response.data.attributes.positions,
      latitude: Number(response.data.attributes.latitude),
      longitude: Number(response.data.attributes.longitude),
      positionTimestamp: response.data.attributes.position_timestamp,
    };
  }

  @waitFor
  async loadFutureVesselPositions(id: string, params: VesselPositionParams) {
    const response = (await this.get(
      this.createUrl(`/v2/vessels/${id}/future_positions`, params),
    )) as unknown as JsonAPI<VesselApiResponse>;

    return {
      id: response.data.id,
      positions: response.data.attributes.positions,
    };
  }

  @waitFor
  async loadFutureVesselPositionsWithCoordinates(id: string, params: VesselPositionWithCoordinatesParams) {
    const response = (await this.get(
      this.createUrl(`/v2/vessels/${id}/future_positions_with_coordinates`, params),
    )) as unknown as JsonAPI<VesselApiResponse>;

    return {
      id: response.data.id,
      positions: response.data.attributes.positions,
    };
  }

  @waitFor
  async loadContainerRoute(id: string): Promise<ContainerRoute> {
    const response = (await this.get(
      this.createUrl(`/v2/containers/${id}/route`),
    )) as unknown as JsonAPI<ContainerRouteApiResponse>;

    return {
      id: response.data.id,
      shipment: this.createShipment(
        response.included.find(({ type }) => type === 'shipment') as unknown as JsonAPI<ShipmentApiResponse>['data'],
      ),
      routeLocations: (
        response.included.filter(
          ({ type }) => type === 'route_location',
        ) as unknown as JsonAPI<RouteLocationApiResponse>['data'][]
      ).map((item) => {
        const location = (
          response.included.filter((item) => item.type === 'port') as unknown as JsonAPI<PortApiResponse>['data'][]
        ).find(
          (port) => port.id === item.relationships.location.data.id,
        ) as unknown as JsonAPI<PortApiResponse>['data'];

        const outboundVessel = (
          response.included.filter((item) => item.type === 'vessel') as unknown as JsonAPI<VesselApiResponse>['data'][]
        ).find((vessel) => vessel.id === item.relationships.outbound_vessel.data?.id);

        const inboundVessel = (
          response.included.filter((item) => item.type === 'vessel') as unknown as JsonAPI<VesselApiResponse>['data'][]
        ).find((vessel) => vessel.id === item.relationships.inbound_vessel.data?.id);

        return {
          id: item.id,
          inboundVoyageNumber: item.attributes.inbound_voyage_number,
          outboundVoyageNumber: item.attributes.outbound_voyage_number,
          inboundEtaAt: item.attributes.inbound_eta_at,
          inboundAtaAt: item.attributes.inbound_ata_at,
          outboundEtdAt: item.attributes.outbound_etd_at,
          outboundAtdAt: item.attributes.outbound_atd_at,
          location: this.createPort(location),
          outboundVessel: outboundVessel ? this.createVessel(outboundVessel) : null,
          inboundVessel: inboundVessel ? this.createVessel(inboundVessel) : null,
        };
      }),
    };
  }

  createVessel(response: JsonAPI<VesselApiResponse>['data']): Vessel {
    return {
      id: response.id,
      name: response.attributes.name,
      latitude: Number(response.attributes.latitude),
      longitude: Number(response.attributes.longitude),
      positions: response.attributes.positions,
      positionTimestamp: response.attributes.position_timestamp,
    };
  }

  createPort(response: JsonAPI<PortApiResponse>['data']): Port {
    return {
      id: response.id,
      name: response.attributes.name,
      code: response.attributes.name,
      stateAbbr: response.attributes.state_abbr || null,
      city: response.attributes.city || null,
      countryCode: response.attributes.country_code,
      latitude: Number(response.attributes.latitude),
      longitude: Number(response.attributes.longitude),
      timeZone: response.attributes.time_zone,
    };
  }

  createShipment(response: JsonAPI<ShipmentApiResponse>['data']): Shipment {
    return {
      id: response.id,
      podTimezone: response.attributes.pod_timezone,
      podEtaAt: response.attributes.pod_eta_at,
      podOriginalEtaAt: response.attributes.pod_original_eta_at,
      lineTrackingLastSucceededAt: response.attributes.line_tracking_last_succeeded_at,
    };
  }

  createUrl(path: string, params?: Record<string, any>) {
    const url = new URL(`${this.config.apiHost}${path}`);

    if (params) {
      for (const [key, value] of Object.entries(params)) {
        url.searchParams.append(key, value);
      }
    }

    return url.toString();
  }

  async get(url: string): Promise<Record<string, unknown>> {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'X-Account-ID': this.config.accountId,
        Authorization: this.config.authToken,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return response.json();
    } else if (response.status === 401) {
      throw new UnauthorizedError(`Not authorized to load: ${url}`);
    } else {
      throw new FailedError(`Failed while loading: ${url}`);
    }
  }
}
