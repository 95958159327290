/* eslint-disable prettier/prettier */
// @ts-ignore
import { cached } from '@glimmer/tracking';

interface RawPosition {
  latitude: number;
  longitude: number;
  timestamp: string;
  heading?: number;
  estimated: boolean;
}

export class Position {
  latitude;
  longitude;
  timestamp;
  heading;
  estimated;
  vesselJourney;
  index;

  constructor(vesselJourney: VesselJourney, position: RawPosition, index: number) {
    this.index = index;
    this.latitude = position.latitude;
    this.longitude = position.longitude;
    this.timestamp = position.timestamp;
    this.heading = position.heading;
    this.estimated = position.estimated;
    this.vesselJourney = vesselJourney;
  }

  get coordinates(): [number, number] {
    return [this.latitude, this.longitude];
  }

  get geojsonCoordinates(): [number, number] {
    return [this.longitude, this.latitude];
  }

  @cached
  get next() {
    return this.vesselJourney.positions[this.index + 1];
  }
}

export class VesselJourney {
  positions: Position[];

  constructor(positions: RawPosition[]) {
    this.positions = positions
      ?.sort((a: RawPosition, b: RawPosition) => {
        if (a.timestamp > b.timestamp) {
          return 1;
        }
        if (a.timestamp < b.timestamp) {
          return -1;
        }

        return 0;
      })
      .map((position: RawPosition, index: number) => {
        return new Position(this, position, index);
      });
  }

  get hasPositions() {
    return this.positions?.length > 0;
  }

  get coordinates() {
    return this.last?.coordinates;
  }

  @cached
  get first() {
    return this.positions[0];
  }

  @cached
  get last() {
    const positions = this.positions;
    return positions && positions[positions?.length - 1];
  }
}
