/* eslint-disable prettier/prettier */
import templateOnlyComponent from '@ember/component/template-only';
import { JourneyPoint } from '../../../../native/journey-point.ts';

interface MarkersPortIconSignature {
  Element: HTMLDivElement;
  Args: {
    journeyPoint: JourneyPoint;
  };
}

const MarkersPortStatusComponent = templateOnlyComponent<MarkersPortIconSignature>();

export default MarkersPortStatusComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TntMap::Markers::Vessel::PortStatus': typeof MarkersPortStatusComponent;
  }
}
