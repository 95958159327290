/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { A } from '@ember/array';
import { getProperties } from '@ember/object';
import { camelize } from '@ember/string';
//@ts-ignore - yet typed 🫠
import filterSerializers from './filter-serializers';

export interface FilterValue {
  id: string;
  name: string;
  type:
    | 'chip'
    | 'boolean'
    | 'boolean-always-true'
    | 'date'
    | 'port'
    | 'port-with-query'
    | 'plain-text'
    | 'tag'
    | 'scac'
    | 'customer'
    | 'vessel-name'
    | 'pod-terminal';
  apiKey: string;
  queryParam: string;
  andQueryParam?: string;
  group: string;
  allowMultiple?: boolean;
  hideFromList?: boolean;
  hideFromQueryParam?: boolean;
  defaultValue?: () => any;
  variant?: string;
  columnIds?: string[];
  meta?: object;
  definition?: any;
  featureFlag?: string;
}

const filters: FilterValue[] = [
  {
    id: 'search_by_ids',
    name: 'BOL / ref / container',
    type: 'plain-text',
    apiKey: 'search_by_ids',
    queryParam: 'filterSearchByIds',
    allowMultiple: false,
    hideFromList: true,
    columnIds: ['shipment_number'],
    defaultValue: () => undefined,
    group: 'Attribute',
  },
  {
    id: 'search_by_owner_id',
    name: 'My Containers',
    type: 'chip',
    apiKey: 'search_by_owner_id',
    queryParam: 'filterSearchByOwnerId',
    allowMultiple: false,
    hideFromList: true,
    hideFromQueryParam: true,
    defaultValue: () => A([]),
    group: 'Attribute',
  },
  {
    id: 'actively_tracked',
    name: 'Actively tracked',
    type: 'boolean',
    apiKey: 'actively_tracked',
    queryParam: 'filterActivelyTracked',
    allowMultiple: false,
    group: 'Status',
  },
  {
    id: 'needs_attention',
    name: 'Needs Attention',
    type: 'boolean-always-true',
    apiKey: 'requires_attention',
    queryParam: 'filterNeedsAttention',
    allowMultiple: false,
    group: 'Status',
  },
  {
    id: 'eta_changed_in_last_24h',
    name: 'POD ETA changed last 24h',
    type: 'boolean-always-true',
    apiKey: 'eta_changed_in_last_24h',
    queryParam: 'filterEtaChangedInLast24h',
    allowMultiple: false,
    group: 'Status',
  },
  {
    id: 'eta_changed_in_past_3_days',
    name: 'POD ETA changed last 3 days',
    type: 'boolean-always-true',
    apiKey: 'eta_changed_in_past_3_days',
    queryParam: 'filterEtaChangedInPast3Days',
    allowMultiple: false,
    group: 'Status',
  },
  {
    id: 'fees',
    name: 'POD Fees',
    type: 'boolean',
    apiKey: 'has_fees',
    queryParam: 'filterHasFees',
    allowMultiple: false,
    group: 'Status',
  },
  {
    id: 'holds',
    name: 'POD Holds',
    type: 'boolean',
    apiKey: 'has_holds',
    queryParam: 'filterHasHolds',
    allowMultiple: false,
    group: 'Status',
  },
  {
    id: 'demurrage_fees',
    name: 'POD Demurrage Fees',
    type: 'boolean',
    apiKey: 'has_demurrage_fees',
    queryParam: 'filterHasDemurrageFees',
    allowMultiple: false,
    group: 'Status',
  },
  {
    id: 'fees_or_holds',
    name: 'POD Fees or Holds',
    type: 'boolean',
    apiKey: 'has_fees_or_holds',
    queryParam: 'filterHasFeesOrHolds',
    allowMultiple: false,
    group: 'Status',
  },
  {
    id: 'is_returned',
    name: 'Returned',
    type: 'boolean',
    apiKey: 'is_returned',
    queryParam: 'filterIsReturned',
    allowMultiple: false,
    group: 'Status',
  },
  {
    id: 'arrival',
    name: 'POD ETA',
    type: 'date',
    apiKey: 'arrival',
    queryParam: 'filterArrival',
    allowMultiple: false,
    defaultValue: () => A([]),
    group: 'Milestone',
  },
  {
    id: 'pol',
    name: 'POL',
    type: 'port-with-query',
    apiKey: 'pol_code',
    queryParam: 'filterPol',
    allowMultiple: false,
    columnIds: ['port_of_origin'],
    meta: { terminus: 'port_of_lading' },
    defaultValue: () => A([]),
    group: 'Location',
  },
  {
    id: 'pod',
    name: 'POD',
    type: 'port-with-query',
    apiKey: 'pod_code',
    queryParam: 'filterPod',
    allowMultiple: false,
    columnIds: ['port_of_discharge'],
    meta: { terminus: 'port_of_discharge' },
    defaultValue: () => A([]),
    group: 'Location',
  },
  {
    id: 'destination',
    name: 'IND',
    type: 'port-with-query',
    apiKey: 'destination_code',
    queryParam: 'filterDestination',
    allowMultiple: false,
    columnIds: ['destination'],
    meta: { terminus: 'destination' },
    defaultValue: () => A([]),
    group: 'Location',
    featureFlag: 'rail-data',
  },
  {
    id: 'inland_destination_rail_unloaded_at',
    name: 'IND Unloaded',
    type: 'date',
    apiKey: 'inland_destination_rail_unloaded_at',
    queryParam: 'filterInlandDestinationRailUnloadedAt',
    allowMultiple: false,
    columnIds: ['inland_destination_rail_unloaded_at'],
    defaultValue: () => A([]),
    group: 'Milestone',
    featureFlag: 'rail-data',
  },
  {
    id: 'pod_rail_departed_at',
    name: 'POD Rail ATD',
    type: 'date',
    apiKey: 'pod_rail_departed_at',
    queryParam: 'filterPodRailDepartedAt',
    allowMultiple: false,
    columnIds: ['pod_rail_departed_at'],
    defaultValue: () => A([]),
    group: 'Milestone',
    featureFlag: 'rail-data',
  },
  {
    id: 'inland_destination_eta_at',
    name: 'Inland Destination ETA',
    type: 'date',
    apiKey: 'inland_destination_eta_at',
    queryParam: 'filterInlandDestinationEtaAt',
    allowMultiple: false,
    columnIds: ['inland_destination_eta_at'],
    defaultValue: () => A([]),
    group: 'Milestone',
    featureFlag: 'rail-data',
  },
  {
    id: 'pickup_facility_lfd_on',
    name: 'IND LFD',
    type: 'date',
    apiKey: 'pickup_facility_lfd_on',
    queryParam: 'filterPickupFacilityLfdOn',
    allowMultiple: false,
    columnIds: ['pickup_facility_lfd_on'],
    defaultValue: () => A([]),
    group: 'Milestone',
    featureFlag: 'rail-data',
  },
  {
    id: 'pol_atd_at',
    name: 'POL ATD',
    type: 'date',
    apiKey: 'pol_atd_at',
    queryParam: 'filterPolAtdAt',
    allowMultiple: false,
    columnIds: ['pol_atd_at'],
    defaultValue: () => A([]),
    group: 'Milestone',
    featureFlag: 'rail-data',
  },
  {
    id: 'pol_etd_at',
    name: 'POL ETD',
    type: 'date',
    apiKey: 'pol_etd_at',
    queryParam: 'filterPolEtdAt',
    allowMultiple: false,
    columnIds: ['pol_etd_at'],
    defaultValue: () => A([]),
    group: 'Milestone',
    featureFlag: 'rail-data',
  },
  {
    id: 'inland_destination_ata_at',
    name: 'IND ATA',
    type: 'date',
    apiKey: 'inland_destination_ata_at',
    queryParam: 'filterInlandDestinationAtaAt',
    allowMultiple: false,
    columnIds: ['inland_destination_ata_at'],
    defaultValue: () => A([]),
    group: 'Milestone',
    featureFlag: 'rail-data',
  },
  {
    id: 'final_destination_full_out_at',
    name: 'IND Full Out',
    type: 'date',
    apiKey: 'final_destination_full_out_at',
    queryParam: 'finalDestinationFullOutAt',
    allowMultiple: false,
    columnIds: ['final_destination_full_out_at'],
    defaultValue: () => A([]),
    group: 'Milestone',
  },
  {
    id: 'pod_arrived_at',
    name: 'POD ATA',
    type: 'date',
    apiKey: 'pod_arrived_at',
    queryParam: 'filterPodArrivedAt',
    allowMultiple: false,
    defaultValue: () => A([]),
    group: 'Milestone',
  },
  {
    id: 'last_free_day_on',
    name: 'POD LFD',
    type: 'date',
    apiKey: 'last_free_day_on',
    queryParam: 'filterLastFreeDayOn',
    allowMultiple: false,
    meta: { withoutPresense: true },
    defaultValue: () => A([]),
    group: 'Milestone',
  },
  {
    id: 'pod_discharged_at',
    name: 'POD Discharged',
    type: 'date',
    apiKey: 'pod_discharged_at',
    queryParam: 'filterPodDischargedAt',
    allowMultiple: false,
    defaultValue: () => A([]),
    group: 'Milestone',
  },
  {
    id: 'tag',
    name: 'Shipment tags',
    type: 'tag',
    apiKey: 'tags',
    queryParam: 'filterTag',
    andQueryParam: 'filterTagAnd',
    defaultValue: () => A([]),
    columnIds: ['shipment_tags'],
    group: 'Attribute',
  },
  // For using AND class standard naming is original query param + And
  {
    id: 'tag_and',
    name: 'Shipment tags AND/OR',
    type: 'boolean',
    apiKey: 'tags_and',
    queryParam: 'filterTagAnd',
    hideFromList: true,
    defaultValue: () => undefined,
    group: 'Attribute',
  },
  {
    id: 'scac',
    name: 'SCAC',
    type: 'scac',
    apiKey: 'shipping_line_scac',
    queryParam: 'filterScac',
    allowMultiple: false,
    defaultValue: () => A([]),
    group: 'Attribute',
  },
  {
    id: 'customer',
    name: 'Customer',
    type: 'customer',
    apiKey: 'customer_id',
    queryParam: 'filterCustomer',
    allowMultiple: false,
    defaultValue: () => A([]),
    group: 'Attribute',
  },
  {
    id: 'picked_up_at',
    name: 'POD Full Out',
    type: 'date',
    apiKey: 'picked_up_at',
    queryParam: 'filterPickedUpAt',
    allowMultiple: false,
    defaultValue: () => A([]),
    group: 'Milestone',
  },
  {
    id: 'empty_returned_at',
    name: 'Empty Returned',
    type: 'date',
    apiKey: 'empty_returned_at',
    queryParam: 'filterEmptyReturnedAt',
    allowMultiple: false,
    defaultValue: () => A([]),
    group: 'Milestone',
  },
  {
    id: 'created_at',
    name: 'Date added',
    type: 'date',
    apiKey: 'created_at',
    queryParam: 'filterCreatedAt',
    allowMultiple: false,
    defaultValue: () => A([]),
    group: 'Milestone',
  },
  {
    id: 'vessel',
    name: 'POD Vessel',
    type: 'vessel-name',
    apiKey: 'vessel_name',
    queryParam: 'filterVesselName',
    allowMultiple: false,
    defaultValue: () => A([]),
    group: 'Attribute',
  },
  {
    id: 'pod_terminal_name',
    name: 'POD Terminal',
    type: 'pod-terminal',
    apiKey: 'pod_terminal_id',
    queryParam: 'filterPodTerminal',
    allowMultiple: false,
    defaultValue: () => A([]),
    group: 'Location',
  },
];

const filtersMap: Map<string, FilterValue> = new Map();

const createOrFilterVariant = (filter: FilterValue) => {
  const variant = 'or';
  return {
    ...filter,
    variant,
    queryParam: camelize(`${variant} ${filter.queryParam}`),
  };
};

filters
  .filter(({ hideFromQueryParam }) => !hideFromQueryParam)
  .forEach((filter) => {
    const copiedFilter = {
      ...filter,
      defaultValue: filter.defaultValue?.(),
    };

    const orVariant = {
      ...createOrFilterVariant(filter),
      defaultValue: filter.defaultValue?.(),
    };

    filtersMap.set(copiedFilter.queryParam, copiedFilter);
    filtersMap.set(orVariant.queryParam, orVariant);
  });

const routerQueryParams: {
  [key: string]: {
    refreshModel: boolean;
  };
} = {};
const routerQueryParamNames: string[] = [];

filtersMap.forEach((_value, key) => {
  const filterName = key;
  routerQueryParams[filterName] = {
    refreshModel: true,
  };
  routerQueryParamNames.push(filterName);
});

const activeQueryParamNames = (queryParams: any) =>
  routerQueryParamNames.filter((f) => queryParams[f] && queryParams[f]?.length);

const activeQueryParams = (queryParams: any) =>
  getProperties(queryParams, activeQueryParamNames(queryParams));

const serializeQueryParamsToApi = (queryParams: any) =>
  Object.fromEntries(
    Object.entries(queryParams).map(([queryParam, value]) => {
      const filter = findSerializerByQueryParam(queryParam);

      // @ts-expect-error
      const filterSerializer = filterSerializers[filter?.type];

      return [
        filter?.apiKey,
        filterSerializer.to_api(filterSerializer.from_url(value)),
      ];
    }),
  );

const findSerializerByQueryParam = (queryParam: any) =>
  Array.from(filtersMap.values()).find(
    (filter) => filter.queryParam === queryParam,
  );

const findFilterByColumnId = (columnId: string) =>
  Array.from(filtersMap.values()).find((filter) =>
    filter.columnIds?.includes(columnId),
  );

/**
 * Map array of FilerValue into groups for ember-power-select
 *
 * @param {FilterValue} filters
 */

const groupFilters = (filters: FilterValue[]) => {
  const groupOptions: {
    [key: string]: FilterValue[];
  } = {};
  for (const filter of filters) {
    const groupName = filter.definition?.group ?? filter.group;
    if (!groupOptions[groupName]) {
      groupOptions[groupName] = [];
    }
    groupOptions[groupName]?.push(filter);
  }
  const groupedFilters: {
    groupName: string;
    options: FilterValue[] | undefined;
  }[] = [];

  for (const groupKey in groupOptions) {
    groupedFilters.push({
      groupName: groupKey,
      options: groupOptions[groupKey],
    });
  }

  return groupedFilters.filter((filterGroup) => filterGroup.options?.length);
};

export {
  groupFilters,
  filters,
  filtersMap,
  findFilterByColumnId,
  findSerializerByQueryParam,
  routerQueryParams,
  routerQueryParamNames,
  activeQueryParamNames,
  activeQueryParams,
  serializeQueryParamsToApi,
};
