import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import leaflet, { type Layer, type Map } from 'leaflet';
import { type PortFeature, type VesselFeature } from '../../../native/geojson/locations.ts';
import { type Vessel } from '../../../native/map-data.ts';

interface MultiMapMarkersVesselSignature {
  Args: {
    vessel: Vessel;
    leafletMapInstance: Map;
    onClickVessel?: (vessel: Vessel) => void;
    isSelected?: boolean;
    isFaded?: boolean;
  };
}

interface VesselStyles {
  colorFill: string;
  strokeColor: string;
  opacity?: number;
}

export default class MultiMapMarkersVesselComponent extends Component<MultiMapMarkersVesselSignature> {
  @tracked isTooltipHovered = false;

  get vesselStyles(): VesselStyles {
    if (this.args.isSelected) {
      if (this.isTooltipHovered) {
        return {
          colorFill: 'var(--yellow-200)',
          strokeColor: 'var(--yellow-600)',
        };
      } else {
        return {
          colorFill: 'var(--yellow-100)',
          strokeColor: 'var(--yellow-600)',
        };
      }
    } else if (this.args.isFaded) {
      if (this.isTooltipHovered) {
        return {
          colorFill: 'var(--gray-200)',
          strokeColor: 'var(--gray-600)',
        };
      } else {
        return {
          colorFill: 'var(--gray-200)',
          strokeColor: 'var(--gray-600)',
          opacity: 0.2,
        };
      }
    } else {
      if (this.isTooltipHovered) {
        return {
          colorFill: 'var(--green-200)',
          strokeColor: 'var(--green-600)',
        };
      } else {
        return {
          colorFill: 'var(--green-100)',
          strokeColor: 'var(--green-600)',
        };
      }
    }
  }

  tooltipWrapperElement = window.document.createElement('div');
  tooltipLayer = leaflet
    .tooltip({
      className: 'tntm__tooltips-vessel',
      offset: [15, 0],
    })
    .setContent(this.tooltipWrapperElement);

  // Set these to the size of the icon inside the template
  realIconSize: [number, number] = [14, 35];
  renderedIconWidth = 14; // Set this to adjust the icon's rendered size. The icon will be scaled to this width

  // Pivot point for the icon. Align this with rendered icon's desired center
  // iconAnchor: [number, number] = [7, 15];

  get iconAnchor(): [number, number] {
    return [this.realIconSize[0] / 2, this.realIconSize[1] / 3];
  }

  get iconSize(): [number, number] {
    return [this.renderedIconWidth, (this.realIconSize[1] * this.renderedIconWidth) / this.realIconSize[0]];
  }

  iconElement = window.document.createElement('div');
  iconLayer = leaflet.divIcon({
    html: this.iconElement,
    iconSize: this.iconSize,
    iconAnchor: this.iconAnchor,
  });

  layer: Layer;

  constructor(owner: unknown, args: MultiMapMarkersVesselSignature['Args']) {
    super(owner, args);

    const { vessel, leafletMapInstance } = this.args;

    this.layer = leaflet
      .geoJSON(
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [vessel.longitude, vessel.latitude],
          },
          properties: null,
        } as VesselFeature,
        {
          onEachFeature: this.onEachFeature,
        },
      )
      .addTo(leafletMapInstance);
  }

  willDestroy(): void {
    super.willDestroy();
    this.layer?.remove();
  }

  onEachFeature = (_feature: PortFeature, layer: leaflet.Layer) => {
    if (layer instanceof leaflet.Marker) {
      // @ts-expect-error
      layer.setRotationAngle(this.args.vessel.navigationalHeadingDegrees);

      const [x, y] = this.iconAnchor;
      // @ts-expect-error
      layer.setRotationOrigin(`${x}px ${y}px`);

      const icon = layer.setIcon(this.iconLayer);
      icon.bindTooltip(this.tooltipLayer);
      icon.addEventListener('mouseover', this.expandTooltip, this);
      icon.addEventListener('mouseout', this.collapseTooltip, this);
      icon.addEventListener('click', this.markerClicked, this);
    }

    layer.addTo(this.args.leafletMapInstance);
  };

  markerClicked = () => {
    this.args.onClickVessel?.(this.args.vessel);
  };

  expandTooltip = () => {
    this.isTooltipHovered = true;
  };

  collapseTooltip = () => {
    this.isTooltipHovered = false;
  };

  updateZIndex = () => {
    // `z-index` only needs to be more than the number of vessels. It is added to the layer's
    // current z-index. When set to 0, its previous value is restored.
    //
    // @ts-ignore Gabor Knows Better™ - seriously: `getLayers()` is in fact a method of `this.layer`...
    this.layer.getLayers()?.[0]?.setZIndexOffset(this.args.isSelected ? 1000 : 0);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MultiMap::Markers::Vessel': typeof MultiMapMarkersVesselComponent;
  }
}
