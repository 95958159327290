import Component from '@glimmer/component';
import leaflet, { type Layer, type Map } from 'leaflet';
import { projectedFutureJourneyPath } from '../../native/geojson/vessel-journey.ts';
import type { JourneyPoint } from '../../native/journey-point.ts';

interface MultiMapFutureVesselPathSignature {
  Element: HTMLDivElement;
  Args: {
    journeyPoint: JourneyPoint;
    leafletMapInstance: Map;
  };
  Blocks: {};
}

export default class MultiMapFutureVesselPathComponent extends Component<MultiMapFutureVesselPathSignature> {
  layer?: Layer;

  constructor(owner: unknown, args: MultiMapFutureVesselPathSignature['Args']) {
    super(owner, args);

    const { journeyPoint } = args;

    if (journeyPoint.isLast) {
      return;
    }

    const projectedPathBetweenPoints = projectedFutureJourneyPath(journeyPoint);

    this.layer = leaflet
      .geoJson(projectedPathBetweenPoints.geojson, {
        style: projectedPathBetweenPoints.style,
        interactive: false,
      })
      .addTo(this.args.leafletMapInstance);
  }

  willDestroy(): void {
    super.willDestroy();
    this.layer?.remove();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MultiMap::FutureVesselPath': typeof MultiMapFutureVesselPathComponent;
  }
}
