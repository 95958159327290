/* eslint-disable prettier/prettier */
import templateOnlyComponent from '@ember/component/template-only';
import { JourneyPoint } from '../../../../native/journey-point.ts';

interface ContainerDetailsMarkersPortIconSignature {
  Element: HTMLDivElement;
  Args: {
    journeyPoint: JourneyPoint | null;
    faSize?: string;
    simple?: boolean;
    isHovered?: boolean;
  };
}

const ContainerDetailsMarkersPortIconComponent = templateOnlyComponent<ContainerDetailsMarkersPortIconSignature>();

export default ContainerDetailsMarkersPortIconComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TntMap::Markers::Port::Icon': typeof ContainerDetailsMarkersPortIconComponent;
  }
}
