import templateOnlyComponent from '@ember/component/template-only';

interface HarborFeedCommentFormSignature {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Args: Record<string, any>;
}

const HarborFeedCommentForm =
  templateOnlyComponent<HarborFeedCommentFormSignature>();

export default HarborFeedCommentForm;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HarborFeedCommentForm: typeof HarborFeedCommentForm;
  }
}
