import Component from '@glimmer/component';
import leaflet, { type Layer, type Map } from 'leaflet';
import { completedVesselJourney } from '../../native/geojson/vessel-journey.ts';
import type { JourneyPoint } from '../../native/journey-point.ts';

interface MultiMapPastVesselPathSignature {
  Element: HTMLDivElement;
  Args: {
    journeyPoint: JourneyPoint;
    leafletMapInstance: Map;
  };
  Blocks: {};
}

export default class MultiMapPastVesselPathComponent extends Component<MultiMapPastVesselPathSignature> {
  layer?: Layer;

  constructor(owner: unknown, args: MultiMapPastVesselPathSignature['Args']) {
    super(owner, args);

    const { journeyPoint } = args;

    if (journeyPoint.isLast) {
      return;
    }

    if (journeyPoint.vesselJourney?.hasPositions) {
      const completedTracks = completedVesselJourney(journeyPoint);

      this.layer = leaflet
        .geoJson(completedTracks.geojson, { style: completedTracks.style, interactive: false })
        .addTo(this.args.leafletMapInstance);
    }
  }

  willDestroy(): void {
    super.willDestroy();
    this.layer?.remove();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MultiMap::PastVesselPath': typeof MultiMapPastVesselPathComponent;
  }
}
