import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class HarborContainersSearchFieldIndexComponent extends Component {
  @action
  onInput(e) {
    this.args.onInput?.(e);
  }

  @action
  onKeydown(e) {
    this.args.onKeydown?.(e);
  }
}
